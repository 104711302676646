import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { SERVER_URL, PATIENT_URL, LOOKUP_URL, DOCTOR_URL, MEDICINE_URL, UPLOAD_URL, DOWNLOAD_URL, HOSPITAL_URL,S3BASE_URL, PYTHON_URL, SWASTH_URL,RAZORPAYMODE, EXPIRYDISCLAIMERSTS, 
  IMAGEBUCKETURL,DEVICETOKEN } from 'src/config';
import { tap } from 'rxjs/operators';
import {HttpClient } from "@angular/common/http";
import { Http } from '@angular/http';
import { HEADER_LOGO,SITE_URL } from "./../config";
import {Storage} from "@ionic/storage";
import {JwtHelperService} from "@auth0/angular-jwt";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  logo_url=HEADER_LOGO;
  site_url=SITE_URL;
  S3BASE_URL=S3BASE_URL;
  SWASTH_URL = SWASTH_URL;
  INDIVIDUAL_1M_PLANID = "plan_GngtoZzv4QWBS9";
  INDIVIDUAL_6M_PLANID = "plan_GvdqwO7eYhFDIJ";
  ONETIME_CONSULTATION = "CIRCLE_1_TIME_CONSULT";
  razorpay_mode=RAZORPAYMODE;
  expiryDisclymStatus = EXPIRYDISCLAIMERSTS;
  imgBucketUrl= IMAGEBUCKETURL;
  deviceToken=DEVICETOKEN;

  authUser = new ReplaySubject<any>(1);
  private jwtTokenName ='jwt_token';

  constructor(private readonly httpClient: HttpClient, private readonly storage: Storage, private readonly jwtHelper: JwtHelperService, private readonly http: Http) {

  }

  // new user sign up
  signup(values: any): Observable<any> {
    return this.httpClient.post(`${PATIENT_URL}/signup`, values, {responseType: 'text'});
      /*.pipe(tap(jwt => {
        console.log("response===="+jwt);
        if (jwt !== 'EXISTS') {          
          return this.handleJwtResponse(jwt);          
        }
        return jwt;
      }));*/
  }

  // new user sign up
  circlesignup(values: any): Observable<any> {
    return this.httpClient.post(`${LOOKUP_URL}/sccirclepatientsignupsc`, values, {responseType: 'text'});
      /*.pipe(tap(jwt => {
        console.log("response===="+jwt);
        if (jwt !== 'EXISTS') {          
          return this.handleJwtResponse(jwt);          
        }
        return jwt;
      }));*/
  }

  // check user logged in
  checkLogin() {
    this.storage.get(this.jwtTokenName).then(jwt => { 
      if (jwt && !this.jwtHelper.isTokenExpired(jwt)) {
        this.authUser.next(jwt);        
      } else {
        //console.log("JWT False"+jwt);
        this.storage.remove(this.jwtTokenName).then(() => this.authUser.next(null));
      }
    });
  }  

  // user session login 
  login(values: any,rememberValues:any): Observable<any> {
    return this.httpClient.post(`${SERVER_URL}/circlelogin`, values, {responseType: 'text'})
    .pipe(tap(jwt => {
      if(jwt !== 'NOTEXISTS'){
        if(rememberValues==1)
        {
          return this.handleJwtResponse(jwt);
        }else{         
          this.authUser.next(jwt);
        }
      }
      return jwt;
    }));      
  }

  // forget password
  forgotUidCheck(ptID: any){
    return this.httpClient.get(`${SERVER_URL}/checkuid/`+ptID, {responseType: "json"});
  }

  // user logout session
  logout() {
    this.storage.remove(this.jwtTokenName).then(() => this.authUser.next(null));
  }

  // store jwt Token
  private handleJwtResponse(jwt: string) {  
    return this.storage.set(this.jwtTokenName,jwt)
      .then(() => this.authUser.next(jwt))
      .then(() => jwt);    
  }

  // get Patient detail by uid
  patientdetail(values: any) {        
    var epurl=LOOKUP_URL+"/scgetcirclepatientdetailssc/"+values;       
    return this.httpClient.get(epurl, {responseType: "json"});          
  }

  //patient address api
  getAddressByUser(userid:any){
    var epurl=PATIENT_URL+"/useraddress/"+userid;
    //console.log("url......"+epurl);
    return this.httpClient.get(epurl, {responseType: "json"});
  }

  // update patient profile
  update(values: any, user: any): Observable<any> {
    var url=PATIENT_URL;
    return this.httpClient.put(`${LOOKUP_URL}/update/`+user, values, {responseType: 'text'});      
  } 


  // edit/add address of patiient
  addAddress(values:any){
    var epurl=PATIENT_URL+"/addaddress";
    return this.httpClient.post(epurl, values, {responseType: "text"});
  }

  // seperate family member account into seperate
  separateAccount(value:any, mobno:String): Observable<any> {
    let url=PATIENT_URL+"/updatesplitaccount/"+mobno;
    return this.httpClient.put(url, value, {responseType: 'text'});
  }

  // View Health Alert
  viewHealthAlert(): Observable<any> {
    var epurl=LOOKUP_URL+"/scviewhealthalertssc";
    //console.log("url......"+epurl);    
    let alertList= this.httpClient.get(epurl, {responseType: "json"});
    //console.log(ptlist);
    return alertList;
  }

  // healthlocker prescription search
  prescriptionSearch(search:any){
    var epurl=MEDICINE_URL+"/prescriptionlist";
    return this.httpClient.put(epurl, search, {responseType: "json"});
  }  

  // get single circle healthlocker prescription list
  singleCirclePrescriptionList(userid:any, search:any){
    var epurl=MEDICINE_URL+"/circleprescriptionlist/"+userid;
    return this.httpClient.put(epurl, search, {responseType: "json"});
  }  

  // single prescription
  uniquePrescription(values: any): Observable<any> {
    var epurl=MEDICINE_URL+"/unique/"+values;
    return this.httpClient.get(epurl, {responseType: "json"});
  }

  //Get doctors list
  doctorsList(): Observable<any> {
    var epurl=DOCTOR_URL+"/profile_list";
    return this.httpClient.get(epurl, {responseType: "json"});
  }

  getDocumentsByDxid(dxid) {
    var pf_url=UPLOAD_URL+"/view_documents/"+dxid;
    // debugger;       
    return this.httpClient.get(pf_url, {responseType: "json"});
  }

  // get doc uid by name
  docUIDSearch(docname:any){
    var epurl=DOCTOR_URL+"/doctoruidbydocname/"+docname;
    return this.httpClient.get(epurl, {responseType: "json"});
  }

  downloadDocumentByKeyname(keyname:any) {
    var pf_url=DOWNLOAD_URL+"/api/file/"+keyname;
    // debugger;       
    return this.httpClient.get(pf_url, {responseType: "text"});
  }

  prescriptionVital(dxid: any): Observable<any> {
    var epurl=MEDICINE_URL+"/vital/"+dxid;
    return this.httpClient.get(epurl, {responseType: "json"});
  }

  //Add QR Code
  getQrCode(values: any): Observable<any> {
    var epurl=MEDICINE_URL+"/getqrcode/"+values;
    return this.httpClient.get(epurl,{responseType: "json"});
  }

  // upload to s3
  uploadFileToS3(uploadObj) {
    let url = UPLOAD_URL + '/api/file/lockdownupload';
    return this.httpClient.post(url, uploadObj, {responseType: "text"});
  }
  
  // send push notification to doctor and patient
  sendPushNotification(notifobj) {
    let url = LOOKUP_URL+"/circlesend";
    return this.httpClient.post(url, notifobj, {responseType: "text"});
  }

  getDocumentKeyName(documentID:any){
    var epurl=UPLOAD_URL+"/get_keyname/"+documentID;
    return this.httpClient.get(epurl, {responseType: "text"});
  }

  getHospitalDetailsByTenantid(tenantid:any){
    var epurl=HOSPITAL_URL+"/hospitalprofile/"+tenantid;
    //console.log("url......"+epurl);
    return this.httpClient.get(epurl, {responseType: "json"});
  }

  // get virtual hospital circle
  getVirtualCircleBySpec(specObj){
    var epurl=LOOKUP_URL+"/scgetvcirclebyspecsc";
    return this.httpClient.put(epurl, specObj, {responseType: "json"});
  }

  // get virtual hospital circle for covid
  getVirtualCircleByCovid(userid){
    var epurl=LOOKUP_URL+"/scgetvcirclecovidsc/"+userid;
    return this.httpClient.put(epurl, {responseType: "json"});
  }

  // get virtual hospital circle for covid
  getVirtualCircleByLive(userid){
    var epurl=LOOKUP_URL+"/scgetvcirclelivesc/"+userid;
    return this.httpClient.put(epurl, {responseType: "json"});
  }

  // get virtual circle detail by circle code
  getCircleDetailByCode(circlecode) {
    return this.httpClient.get(`${LOOKUP_URL}/scgetvcircledocbycodesc/`+circlecode, {responseType: "json"});
  }

  // get virtual circle doc detail by docuid array
  getCircleDocProfile(docuidlist) {
    var epurl=LOOKUP_URL+"/scgetvcircledocdetailsc";
    return this.httpClient.put(epurl, docuidlist, {responseType: "json"});
  }

  // get swasth subscribe plan list
  getSubscribPlanList() {
    var epurl=LOOKUP_URL+"/scgetplanmastersc";
    return this.httpClient.get(epurl, {responseType: "json"});
  }

  // get razorpay subscribe plan list
  getRazorpaySubscribPlanList() {
    var epurl=LOOKUP_URL+"/scviewplanssc";
    return this.httpClient.get(epurl, {responseType: "json"});
  }

  // create razorpay subscribe 
  createRazorpaySubscribe(subscribeObj) {
    var epurl=LOOKUP_URL+"/sccreatesubscriptionsc";
    return this.httpClient.post(epurl, subscribeObj, {responseType: "json"});
  }

  // renew razorpay subscribe 
  renewRazorpaySubscribe(subscribeObj, subscriptionid) {
    var epurl=LOOKUP_URL+"/screnewsubscriptionsc/"+subscriptionid;
    return this.httpClient.patch(epurl, subscribeObj, {responseType: "json"});
  }

  // cancel razorpay subscribe 
  cancelRazorpaySubscribe(subscribeObj, subscriptionid) {
    var epurl=LOOKUP_URL+"/sccancelsubscriptionsc/"+subscriptionid;
    return this.httpClient.post(epurl, subscribeObj, {responseType: "json"});
  }

  // create chat restriction after subscription
  createChatRestriction(chatrestrictobj) {
    var epurl=LOOKUP_URL+"/sccreatechatrestrictsubscriptionsc";
    return this.httpClient.post(epurl, chatrestrictobj, {responseType: "text"});
  }

  // create subscription history
  createSubscriptionHistory(subscriptionObj) {
    var epurl=LOOKUP_URL+"/sccreatesubscriptionhistorysc";
    return this.httpClient.post(epurl, subscriptionObj, {responseType: "text"});
  }

  // cancel circle subscription
  cancelCircleSubscription(subscriptionObj) {
    var epurl=LOOKUP_URL+"/cancelcirclesubscription";
    return this.httpClient.post(epurl, subscriptionObj, {responseType: "text"});
  }

  //	get cancel subscription history
  cancelledSubscriptionHistory(subscriptionid) {
    var epurl=LOOKUP_URL+"/getcancelsubscriptionhistory/"+subscriptionid;
    return this.httpClient.get(epurl, {responseType: "json"});
  }

  //	get patient subscription history
  getSubscriptionHistory(ptuid, circlecode) {
    var epurl=LOOKUP_URL+"/scgetptsubscriptionhistorysc/"+ptuid+"/"+circlecode;
    return this.httpClient.get(epurl, {responseType: "json"});
  }

  // pause razorpay subscription by id
  pauseSubscription(subid) {
    var epurl=LOOKUP_URL+"/pauserazorpaysubscription/"+subid;
    return this.httpClient.post(epurl, {responseType: "json"});
  }

  // resume razorpay subscription by id
  resumeSubscription(subid) {
    var epurl=LOOKUP_URL+"/resumerazorpaysubscription/"+subid;
    return this.httpClient.post(epurl, {responseType: "json"});
  }

  // get pt chat restriction detail
  getChatRestrictDetail(value:any) {
    let url = LOOKUP_URL+"/scgetptchatrestricdetailsc";
    return this.httpClient.put(url, value, {responseType: "json"});
  }

  // add vchat complaints
  addChatComplaint(chatcomplaintobj) {
    var epurl=LOOKUP_URL+"/scaddvchatcomplaintsc";
    return this.httpClient.post(epurl, chatcomplaintobj, {responseType: "json"});
  }

  // create admin doc pt chat restriction complaint
  adminPtChatRestriction(value:any) {
    var epurl=LOOKUP_URL+"/sccreateadmindocchatrestrictsc";
    return this.httpClient.post(epurl, value, {responseType: 'text'});
  }

  // get patient chat by patient userid and circle code
  getPatientChatByPatientUserid(userid,circleCode) {
    let url = LOOKUP_URL+"/scgetpatientchatsc/"+userid+"/"+circleCode;
    return this.httpClient.get(url, {responseType: "json"});
  }

  // get doctor specialization list
  getDoctorSpecialization() {
    let url = LOOKUP_URL+"/scgetspecialisationsc";
    return this.httpClient.get(url, {responseType: "json"});
  }

  // get doctor specialization list
  getDoctorPrimarySpecialization() {
    let url = LOOKUP_URL+"/scgetprimaryspecialisationsc";
    return this.httpClient.get(url, {responseType: "json"});
  }

  // add patient to circle
  addPtToCircle(values:any) {
    var epurl=LOOKUP_URL+"/scaddptcirclesc";
    return this.httpClient.post(epurl, values, {responseType: "json"});
  }

  // add patient admin chat detail
  createPtChat(values:any) {
    var epurl=LOOKUP_URL+"/sccreatepatientchatsc";
    return this.httpClient.post(epurl, values, {responseType: "json"});
  }

  // get patient subscribed circle code list
  getPtCircleList(ptuid:any) {
    let url = LOOKUP_URL+"/scgetptcirclecodelistsc/"+ptuid;
    return this.httpClient.get(url, {responseType: "json"});
  }

  // get circle detail for doctor
  getCircleDetail(circleCode:any) {
    let url = LOOKUP_URL+"/scgetcircledetailsc";
    return this.httpClient.put(url,circleCode, {responseType: "json"});
  }

  // get chat by userid and circle code
  getChatByUserid(userid,circleCode) {
    let url = LOOKUP_URL+"/scgetchatdeatilssc/"+userid+"/"+circleCode;
    return this.httpClient.get(url, {responseType: "json"});
  }

  // triage reference doctor
  triageRefDoctor(triageobj:any) {
    var epurl=LOOKUP_URL+"/sctriagetodoctorchatsc";
    return this.httpClient.post(epurl, triageobj, {responseType: 'json'});
  }

  // get chat format detail of patient for triaging doctor
  getChatFormatDetailPt(value:any) {
    var epurl=LOOKUP_URL+"/scgetchatformatsc"; 
    return this.httpClient.put(epurl, value, {responseType: "json"});
  }

  // add family member
  addFamilyMember(familyMember:any) {
    let url = LOOKUP_URL+"/scaddfamilymembersc";
    return this.httpClient.post(url,familyMember, {responseType: "text"});
  }  

  addAllFamilyMember(familyMember:any) {
    let url = LOOKUP_URL+"/scaddallfamilymemberssc";
    return this.httpClient.post(url,familyMember, {responseType: "text"});
  } 

  // get all circle list detail 
  getAllCircleDetailList() {
    let url = LOOKUP_URL+"/scgetallvcirclelistsc";
    return this.httpClient.get(url, {responseType: "json"});
  }

  // get virtual consultation fee
  getConsultFeeDetailSingleCircle(billobj:any) {
    let url = LOOKUP_URL+"/scgetvirtualconsultfeesc";
    return this.httpClient.put(url,billobj, {responseType: "json"});
  }

  // get virtual consultation fee
  getConsultFeeDetailAllCircle(billobj:any, ptuid:any) {
    let url = LOOKUP_URL+"/scgetallcirclevirtualconsultfeesc/"+ptuid;
    return this.httpClient.put(url,billobj, {responseType: "json"});
  }

  // update chat format history for triage detail
  updateChatFormat(value:any, formatid:any) {
    var epurl=LOOKUP_URL+"/scupdatechatformatsc/"+formatid;
    return this.httpClient.put(epurl, value, {responseType: 'text'});
  }

  // update virtual consultation fee
  updateConsultFee(billobj) {
    let url = LOOKUP_URL+"/scupdatevirtualconsultfeesc";
    return this.httpClient.post(url,billobj, {responseType: "text"});
  }

  // update patient profile
  updateCirclePatientAccountType(userid: any, accountType: any) {
    let url = LOOKUP_URL+"/scupdateaccounttypesc/"+userid+"/"+accountType;
    return this.httpClient.get(url, {responseType: "text"});      
  } 

  // get Family Members

  getFamilyMember(primary_userid)
  {
    let url = LOOKUP_URL+"/scgetfamilymemberssc/"+primary_userid;
    return this.httpClient.get(url, {responseType: "json"});
  }

  //get primary user profile

  getPrimaryUserProfile(userid)
  {
    let url = LOOKUP_URL+"/scgetcirclepatientdetailssc/"+userid;
    return this.httpClient.get(url, {responseType: "json"});
  }

  // get family member profile
  getFamilyMemberProfile(userid)
  {
    let url = LOOKUP_URL+"/scgetfamilymemberprofilesc/"+userid;
    return this.httpClient.get(url, {responseType: "json"});
  }

  //get circle code by family primary user id

  getCircleCode(userid,subscriptiontype)
  {
    let url = LOOKUP_URL+"/scgetcirclecodesc/"+userid+"/"+subscriptiontype;
    return this.httpClient.get(url, {responseType: "text"});
  }

  // get circle entity by circle code
  getCircleEntity(circleCode) {
    let url = LOOKUP_URL+"/scgetcirclesc/"+circleCode;
    return this.httpClient.get(url, {responseType: "json"});
  }

  // get doctor profile by userid
  getDoctorProfile(docuserID: any){
    ////console.log("Doctor---"+docuserID);    
    return this.httpClient.get(`${DOCTOR_URL}/profile/`+docuserID, {responseType: "json"});
  }

  // get triage doctor chat detail
  getPtTriageDocChatDetail(uid,docid,ccode) {
    let url = LOOKUP_URL+"/scgettriagedoctorchatsc/"+uid+"/"+docid+"/"+ccode;
    return this.httpClient.get(url, {responseType: "json"});
  }

  // verify family member

  checkFamilyMember(userid)
  {
    let url = LOOKUP_URL+"/scverifyfamilymembersc/"+userid;
    return this.httpClient.get(url, {responseType: "json"});
  }

  // check patient subscribed in circle

  checkPatientSubscribedInCircle(userid,circlecode)
  {
    let url = LOOKUP_URL+"/sccheckpatientsubscripedincirclesc/"+userid+"/"+circlecode;
    return this.httpClient.get(url, {responseType: "json"});
  }

  // save followup clinic appt
  saveFollowUpClinicAppt(apptList) {
    let url = LOOKUP_URL+"/savefollowupclinicappt";
    return this.httpClient.post(url,apptList, {responseType: "text"});
  }

  // get followup clinic appt
  getFollowUpClinicAppt(ptUid, circCode) {
    let url = LOOKUP_URL+"/getptfollowupappt/"+ptUid+"/"+circCode;
    return this.httpClient.get(url,{responseType: "json"});
  }

  // get mobile country code list
  getCountryCodes() {
    var epurl=LOOKUP_URL+"/sccountrycodessc";
    return this.httpClient.get(epurl, {responseType: "json"});
  }

  // get circle obj and  circle doctor list
  getCircleDocListByCcode(circleName) {
    var epurl=LOOKUP_URL+"/scgetvcircledoclistbyccodesc/"+circleName;
    return this.httpClient.get(epurl, {responseType: "json"});
  }

  // update chat request count
  updateChatRequestCount(userid,circlecode,type) {
    var epurl=LOOKUP_URL+"/scupdatechatrequestcountsc/"+userid+"/"+circlecode+"/"+type;
    return this.httpClient.put(epurl, {responseType: 'text'});
  }

  // Chat Bot API
  // getSpecialization(apiobj)
  // {
  //   console.log("bot obj",apiobj);
  //   var epurl="http://54.169.13.234:5001/getailment";
  //   return this.httpClient.post(epurl,apiobj,{responseType:'json'});
  // }

  // get user subscriptions
  getUserSubscriptions(userid) {
    var epurl=LOOKUP_URL+"/scgetusersubscribessc/"+userid;
    return this.httpClient.get(epurl, {responseType: 'json'});
  }

  // get user subscriptions
  updatePtChatListSt(ccode, userid, status) {
    var epurl=LOOKUP_URL+"/scupdateadminusercstatussc/"+ccode+"/"+userid+"/"+status;
    return this.httpClient.get(epurl, {responseType: 'json'});
  }
  
  // get pt chat restriction detail by user id circle code and subscription type
  getChatRestrictDetailBySubtype(value:any) {
    let url = LOOKUP_URL+"/scgetptchatrestricdetailbysubtypesc";
    return this.httpClient.put(url, value, {responseType: "json"});
  }

  // get circle doctor list
  getCircleDocList(circleCode) {
    let url = LOOKUP_URL+"/scgetcircledoclistsc/"+circleCode;
    return this.httpClient.get(url, {responseType: "json"});
  }

  // check circle patient mobileno, email
  checkPtMobRegEmail(obj)
  {
    var epurl=LOOKUP_URL+"/sccheckptmobemailsc";
    return this.httpClient.put(epurl, obj, {responseType: "json"});
  }

  // bot api call. get circle by patient existing illness
  getCircleByIllness(obj)
  {
    var epurl=PYTHON_URL;
    return this.httpClient.post(epurl, obj, {responseType: "json"});
  }
    
  // get patient subscribed single circle 
  getPtSingleCircle(ccode, ptuid:any) {
    let url = LOOKUP_URL+"/scgetptsinglecirclesc/"+ccode+"/"+ptuid;
    return this.httpClient.get(url, {responseType: "json"});
  }

  // get patient subscribed single circle with chat restriction
  getPtSingleCircleWithChatRestrict(ccode, ptuid:any) {
    let url = LOOKUP_URL+"/scgetptcircledetailandchatrestrictionsc/"+ccode+"/"+ptuid;
    return this.httpClient.get(url, {responseType: "json"});
  }

  // update Patient FCM Token
  updatePtFcmToken(userid, fcm_token,update_time) {
    var epurl=LOOKUP_URL+"/updatepatientfcmtoken/"+userid+"/"+fcm_token+"/"+update_time;
    return this.httpClient.get(epurl, {responseType: 'text'});
  }

  // save circle video consultation appointment
  saveCircleVideoAppt(consultObj) {
    var epurl=LOOKUP_URL+"/savecirclevideoappt";
    return this.httpClient.put(epurl, consultObj, {responseType: "text"});
  }

  // get circle doctors single day time slots 
  getCircleDocsSingleDayslots(docuidAndccdaydate) {
    var epurl=LOOKUP_URL+"/getcirclealldoctimeSession";
    return this.httpClient.put(epurl, docuidAndccdaydate, {responseType: "json"});
  }

  // get circle doctors single day time slots 
  getSingleCirDocDayslots(docuidcirdaydate) {
    var epurl=LOOKUP_URL+"/getsingcirdocdayts";
    return this.httpClient.put(epurl, docuidcirdaydate, {responseType: "json"});
  }

  // generate pdf servic side retun key
  pdfGenerate(pdfObj, dxid) {
    var epurl=MEDICINE_URL+"/pdfgenerate/"+dxid;
    return this.httpClient.put(epurl, pdfObj, {responseType: 'text'});
  }

  // download document as base64 by keyname
  downloadDocumentAsBase64ByKeyname(keyname:any)
  {
    var pf_url=DOWNLOAD_URL+"/api/prescriptionfile/"+keyname;
    //console.log(pf_url);
    // debugger;       
    return this.httpClient.get(pf_url, {responseType: "text"});
  }

  // update patient triage history
  updatePtTriageHistory(updateObj) {
    var epurl=LOOKUP_URL+"/scupdatetriagehistorysc";
    return this.httpClient.put(epurl, updateObj, {responseType: 'text'});
  }

  // chat room doctor single day consultation time
  getCircleDocTimeSession(obj) {
    var epurl=LOOKUP_URL+"/getcircledoctimeSessionbydateorweek";
    return this.httpClient.put(epurl, obj, {responseType: "json"});
  }

  // get virtual bill object by bill id
  getSingleBillById(billid:any)
  {
    var pf_url=LOOKUP_URL+"/scgetsinglebillbyidsc/"+billid;
    //console.log(pf_url);
    return this.httpClient.get(pf_url, {responseType: "json"});
  }

  // lockdown upload Prescription
  uploadPrescriptionToS3(body){
    var epurl=UPLOAD_URL + '/api/file/lockdownupload';
    //console.log("url......"+epurl);
    return this.httpClient.post(epurl, body, {responseType: "text"});
  }

  // lockdown upload Prescription
  // razorpayAmtTransToDocAccViaPayment(body, payment_id){
  //   var epurl=LOOKUP_URL + '/sctransviapayidtoaccsc/'+payment_id;
  //   //console.log("url......"+epurl);
  //   return this.httpClient.post(epurl, body, {responseType: "json"});
  // }

  // get virtual hospital circle by speclist and user not subscribed circles
  getVirtualCircleBySpecUserid(specObj, userid){
    var epurl=LOOKUP_URL+"/scgetvcbyspecuidsc/"+userid;
    return this.httpClient.put(epurl, specObj, {responseType: "json"});
  }

  // get circle obj and  circle doctor list by speclist and user not subscribed circles
  getCircleDocListByCnameNonScb(circleName, userid) {
    var epurl=LOOKUP_URL+"/scgetvcircledoclistbycnamenonscbsc/"+circleName+"/"+userid;
    return this.httpClient.get(epurl, {responseType: "json"});
  }

  // create subscribe entry before pay patientt circle, chat, chatrestrict, subscribehistory
  createSubscribeBeforePay(subscribeObj, subscpType) {
    var epurl="";
    if(this.razorpay_mode=="Live")
    {
      epurl=LOOKUP_URL+"/scsavescriptionobjcirclesc/"+subscpType;
    }
    else
    {
      epurl=LOOKUP_URL+"/sctestsavescriptionobjsc/"+subscpType;
    }    
    return this.httpClient.put(epurl, subscribeObj, {responseType: "json"});
  }

  // update subscribe entry after pay patientt circle, chat, chatrestrict, subscribehistory
  updateSubscribeBeforePay(subscribeObj) {
    var epurl=LOOKUP_URL+"/scupatescriptionobjsc";
    return this.httpClient.post(epurl, subscribeObj, {responseType: "text"});
  }

  razorpayAmtTransToDocAccViaPayment(routeObj){
    var epurl="";
    if(this.razorpay_mode=='Live')
    {
      epurl=LOOKUP_URL+ '/scgetpaydetailposttodocaccsc';
    }
    else
    {
      epurl=LOOKUP_URL+ '/sctestgetpaydetailposttodocaccsc';
    }
    
    return this.httpClient.post(epurl, routeObj, {responseType: "json"});
  }

  // send email to patient to renewal the subscriiption
  sendMailToRenewalSubscp(sendObj){
    var epurl=LOOKUP_URL+"/email";
    return this.httpClient.put(epurl, sendObj, {responseType: "text"});
  }

  // save manual renewal after payment
  saveManualRenewal(postObj){
    var epurl=LOOKUP_URL+"/scsavemanualrenewsc";
    return this.httpClient.post(epurl, postObj, {responseType: "text"});
  }

  // get patient manual subscription to send renewal link to email
  getManualSubscpRenew(postObj): Observable<any> {
    var epurl=LOOKUP_URL+"/scptrenewalstssc";
    return this.httpClient.post(epurl, postObj, {responseType: "json"});
  }

  // upddate renewal email send status
  updateRenewEmailStatus(putObj): Observable<any> {
    var epurl=LOOKUP_URL+"/scupdatemanualrenewstsc";
    return this.httpClient.put(epurl, putObj, {responseType: "text"});
  }

  // save covid complaint
  saveCovidComplaint(value:any) {
    var epurl=LOOKUP_URL+"/savecovidprescreening";
    return this.httpClient.post(epurl, value, {responseType: 'text'});
  }

  // get covid complaint
  getCovidComplaint(ptuid, ccode) {
    var epurl=LOOKUP_URL+"/getcovidprescreening/"+ccode+"/"+ptuid;
    return this.httpClient.get(epurl, {responseType: "json"});
  }

  // create subscribe entry covid free plan patientt circle, chat, chatrestrict, subscribehistory
  createSubscribeCovidFreePlan(subscribeObj) {
    var epurl=LOOKUP_URL+"/scsavescriptionobjfreeplansc";
    return this.httpClient.post(epurl, subscribeObj, {responseType: "json"});
  }
  
  // update status for covid
  updateChatStatusforCovid(ptuid, ccode,docuserid) {    
    var epurl=LOOKUP_URL+"/scupdatechatstatuscovidsc/"+ptuid+"/"+ccode+"/"+docuserid;
    return this.httpClient.get(epurl, {responseType: 'text'});
  }

  //check subscribe payment status
  checkCirclePayStatus(ccode, ptuid): Observable<any> {
    var epurl=LOOKUP_URL+"/scchecksubscppaystsc/"+ptuid+"/"+ccode;
    return this.httpClient.get(epurl, {responseType: 'json'});
  }

  changePassAllUser(value:any, userid:any){
    let url=LOOKUP_URL+"/changePassAll/"+value+"/"+userid;
    return this.httpClient.put(url, {responseType: 'text'});
  }

//   	check subscription expire date
  checkPtSubscpExpireSt(obj:any): Observable<any> {
    let url=LOOKUP_URL+"/scchecksubexpirestsc";
    return this.httpClient.post(url, obj, {responseType: 'json'});
  }  

  // store subscribe renewal for covid
  saveRenewalDetail(renewObj, userid, ccode): Observable<any> {
    let url="";
    if(this.razorpay_mode=='Live')
    {
      url=LOOKUP_URL+"/screnewalcirclesc/"+userid+"/"+ccode;
    }
    else
    {
      url=LOOKUP_URL+"/sctestrenewalcirclesc/"+userid+"/"+ccode;
    }    
    return this.httpClient.put(url, renewObj, {responseType: 'json'});
  }

  // update renewal py and subscription, chat, chat resctrict status 
  updateRenewalSubscpchatst(updateObj): Observable<any> {
    let url=LOOKUP_URL+"/scupdateRenewalsc";
    return this.httpClient.put(url, updateObj, {responseType: 'text'});
  }

  // get virtual hospital circle
  getVirtualCircleBySpecFiveDocUser(specObj, userid){
    var epurl=LOOKUP_URL+"/scgetvcirclebyspecdocusersc/"+userid;
    return this.httpClient.put(epurl, specObj, {responseType: "json"});
  }

  //	get plan details based on plan id
  getPlanDetails(planid) {
    var epurl=LOOKUP_URL+"/getplandetailsbyid/"+planid;
    return this.httpClient.get(epurl, {responseType: "json"});
  }

  // get circle video appt of patient
  getVideoApptOfPt(apptObj) {
    var epurl=LOOKUP_URL+"/getvideoapptofpatient";
    return this.httpClient.put(epurl, apptObj,{responseType: "json"});
  }

  // get circle video appt of patient for all doctors
  getVideoApptOfPtForAllDoctors(apptObj) {
    var epurl=LOOKUP_URL+"/getvideoapptofpatientalldoctor";
    return this.httpClient.put(epurl, apptObj,{responseType: "json"});
  }

  //	create razorpay order for consultaton payment
  createConsultPayOrder(orderObj): Observable<any> {
    let epurl="";
    if(this.razorpay_mode=='Live') {
      epurl=LOOKUP_URL+"/scconsultordersc";
    } else {
      epurl=LOOKUP_URL+"/sctestconsultordersc";
    }
    return this.httpClient.post(epurl, orderObj, {responseType: "json"});
  }

  // get patiet current manual subscription plan id
  getPtCurSubscpPlanID(subscpHisId): Observable<any> {
    let epurl=LOOKUP_URL+"/scgetcurplanidsc/"+subscpHisId;
    return this.httpClient.get(epurl, {responseType: "json"});
  }

  // get patiet single circle manual subscription renewal validity
  getPtsingCirRenewalStatus(subscpObj): Observable<any> {
    let epurl=LOOKUP_URL+"/scsincirmanualrenewchecksc";
    return this.httpClient.put(epurl, subscpObj, {responseType: "json"});
  }

  // activate admin doc pt chat
  activateAdminPtChat(value:any) {
    var epurl=LOOKUP_URL+"/scactivateadmindocchatsc";
    return this.httpClient.post(epurl, value, {responseType: 'json'});
  }

  // Add Circle Family Members
  addCircleFamilyMembers(familyMember:any) {
    let url = LOOKUP_URL+"/scaddcirclefamilymemberssc";
    return this.httpClient.post(url,familyMember, {responseType: "text"});
  } 

  // Add Single Circle Family Member
  addCircleSingleFamilyMember(familyMember:any) {
    let url = LOOKUP_URL+"/scaddcirclesinglefamilymembersc";
    return this.httpClient.post(url,familyMember, {responseType: "text"});
  } 

  // Get Circle Family Members
  getCircleFamilyMembersList(ptprimaryuserid) {
    var epurl=LOOKUP_URL+"/getcirclefamilymembers/"+ptprimaryuserid;
    return this.httpClient.get(epurl, {responseType: "json"});
  }

  // Get Circle Family Members
  getCircleFamilyMembersListByCircleCode(ptprimaryuserid) {
    var epurl=LOOKUP_URL+"/getcirclefamilymembersbycirclecode/"+ptprimaryuserid;
    return this.httpClient.get(epurl, {responseType: "json"});
    
  }

  // Get Circle Family Members By Userid and Circle Code
  getCircleFamilyMembersListByCircleCodeAndPtuid(ptprimaryuserid,circlecode) {
    var epurl=LOOKUP_URL+"/getcirclefamilymembersuseridcirclecode/"+ptprimaryuserid+"/"+circlecode;
    return this.httpClient.get(epurl, {responseType: "json"});
  }

  addbillingdocument(values: any): Observable<any> {
   // console.log(JSON.stringify(values));
    return this.httpClient.post(`${UPLOAD_URL}/add_billing_document`, values, {responseType: 'text'});      
  }

  addDxCenterdocument(values: any): Observable<any> 
  {
    //console.log(JSON.stringify(values));
    return this.httpClient.post(`${UPLOAD_URL}/add_dxcenter_document`, values, {responseType: 'text'});
  }

  addprescriptiondocument(values: any): Observable<any> {
    //console.log(JSON.stringify(values));
    return this.httpClient.post(`${UPLOAD_URL}/add_prescription_document`, values, {responseType: 'text'});      
  }

  diagnosticDocumentList(userID:any): Observable<any>{
    var epurl=UPLOAD_URL+"/diagnosis_investigation_list/"+userID;
    //console.log("url......"+epurl);
    // console.log(this.httpClient.get(epurl, {responseType: "json"}));
    return this.httpClient.get(epurl, {responseType: "json"});
  }

  // healthlocker prescription by user id
  prescriptionListByUserID(uid:any): Observable<any>{
    var epurl=MEDICINE_URL+"/precsriptionList/"+uid;
    return this.httpClient.get(epurl, {responseType: "json"});
  }

  // create bill for virtual consultation
  createBillForConsultation(billobj:any) {
    //console.log("chat obj---",billobj); 
    let url = LOOKUP_URL+"/sccreatebillforconsultsc";
    return this.httpClient.post(url, billobj, {responseType: "text"});
  }

  // get user all bills
  getAllBills(userid): Observable<any> {
    //console.log("chat obj---",userid); 
    let url = LOOKUP_URL+"/scgetptbillssc/"+userid;
    return this.httpClient.get(url, {responseType: "json"});
  }

  // get patiet subscribed circle expired status
  getPtSubscpExpiredStatus(userid): Observable<any> {
    //console.log("chat obj---",userid); 
    let url = LOOKUP_URL+"/sccheckptcirclesexpirysc/"+userid;
    return this.httpClient.get(url, {responseType: "json"});
  }

  // get patiet subscribed circle expired status
  getPtAllPrescWithDocus(userid): Observable<any> {
    //console.log("chat obj---",userid); 
    let url = MEDICINE_URL+"/precsriptionlistdocs/"+userid;
    return this.httpClient.get(url, {responseType: "json"});
  }

  // create bill for virtual consultation
  createBillForVideoConsultationAppt(billobj:any): Observable<any> {
    //console.log("chat obj---",billobj); 
    let url = LOOKUP_URL+"/sccreatebillforvideoconsultsc";
    return this.httpClient.post(url, billobj, {responseType: "json"});
  }

  // get followup plans by circle code doctor uid and status
  getFollowupsPlanByDocCcode(ccode, docuid, status): Observable<any> {
    var epurl=LOOKUP_URL+"/scplanbyccodesc/"+ccode+"/"+docuid+"/"+status;
    return this.httpClient.get(epurl, {responseType: 'json'});
  }

  // get video appointment count by patient uid, circle code and date for one time consult payment 
  getApptCountByPtuidCcodeDate(ptuid, subscbdate, ccode): Observable<any> {
    var epurl=LOOKUP_URL+"/getvideoapptcountofsingconsul/"+ptuid+"/"+subscbdate+"/"+ccode;
    //console.log("url-->",epurl);
    return this.httpClient.get(epurl, {responseType: 'json'});
  }

  //	check tagging created for patient by userid ccode docuserid planid
  checkTaggingForPatient(ptuid,planid,docuid,ccode): Observable<any> {
    var epurl=LOOKUP_URL+"/checktagforpt/"+ptuid+"/"+docuid+"/"+planid+"/"+ccode;
   // console.log("hffy->",epurl);
    return this.httpClient.get(epurl, {responseType: "json"});
  }

  // Get corporate user profile

  getCorporateUserProfile(ptemailid)
  {
    var epurl=LOOKUP_URL+"/getcorporateuserprofilebyemail/"+ptemailid;
    return this.httpClient.get(epurl, {responseType: 'json'});
  }

  // update otp status

  updateOtpStatus(ptemailid)
  {
    var epurl=LOOKUP_URL+"/updateotpstatus/"+ptemailid;
    return this.httpClient.put(epurl, {responseType: 'text'});
  }

  // update otp status

  updateCorpPasswordStatus(ptemailid)
  {
    var epurl=LOOKUP_URL+"/updatepasswordstatus/"+ptemailid;
    return this.httpClient.put(epurl, {responseType: 'text'});
  }

  // update otp status
  updateNewpassword(ptemailid,newpassword)
  {
    var epurl=LOOKUP_URL+"/updatepassword/"+ptemailid+"/"+newpassword;
    return this.httpClient.put(epurl, {responseType: 'text'});
  }
  
  // update pdf keyname for followup handbook
  updatehandbookKey(hbObj): Observable<any> {
    var epurl=LOOKUP_URL+"/scupatekeyofhbsc";
    return this.httpClient.post(epurl, hbObj, {responseType: 'json'});
  }

  // Send Video Appointment SMS to Doctor

  sendVideoApptSMSToDoctor(drMobile, patientName, scheduleDate, doctorName, websiteLink)
  {
    var epurl=LOOKUP_URL+"/sendvideoappointmentsmsdoctormsg/"+drMobile+"/"+patientName+"/"+scheduleDate+"/"+doctorName+"/"+websiteLink;
    return this.httpClient.get(epurl, {responseType: 'json'});
  }

  // Send Video Appointment SMS to Doctor

  sendVideoApptSMSToPatient(ptMobile, patientName, scheduleDate, doctorName, websiteLink)
  {
    var epurl=LOOKUP_URL+"/sendvideoappointmentsmspatientmsg/"+ptMobile+"/"+patientName+"/"+scheduleDate+"/"+doctorName+"/"+websiteLink;
    return this.httpClient.get(epurl, {responseType: 'json'});
  }

  // get agora video call details

  getAgoraVideoDetails(circlecode,doctoruid,patientuid)
  {
    let url = LOOKUP_URL+"/getagoradetails/"+circlecode+"/"+doctoruid+"/"+patientuid;
    return this.httpClient.get(url, {responseType: "json"});
  }

  // get agora video call details by patient uid

  getAgoraVideoDetailsByPatientuserid(patientuid)
  {
    let url = LOOKUP_URL+"/getagoradetailsbyptuid/"+patientuid;
    return this.httpClient.get(url, {responseType: "json"});
  }

  // update subscription request count
  updateSubscriptionBillPdfLink(subscription_id,pdflink) {
    var epurl=LOOKUP_URL+"/updatesubscriptionbilllink/"+subscription_id+"/"+pdflink;
    return this.httpClient.put(epurl, {responseType: 'text'});
  }

  // Get Server Time

  getServerTime()
  {
    let url = LOOKUP_URL+"/getServerDateTime/";
    return this.httpClient.get(url, {responseType: "text"});
  }
  
  // survey details save  
  saveSurveyDetails(serveyobj) {
    var epurl=LOOKUP_URL+"/savedocengagdetail";
    return this.httpClient.post(epurl, serveyobj, {responseType: 'text'});
  }

  // check survey details is created  
  checkSurveyDetails(ccode, ptuid, planid) {
    var epurl=LOOKUP_URL+"/checkdocengagdetail/"+ccode+"/"+ptuid+"/"+planid;
    return this.httpClient.get(epurl, {responseType: 'text'});
  }
  

  // update Patient Web FCM Token
  updatePtWebFcmToken(userid, web_fcm_token,update_time) {
    var epurl=LOOKUP_URL+"/updatepatientwebfcmtoken/"+userid+"/"+web_fcm_token+"/"+update_time;
    return this.httpClient.get(epurl, {responseType: 'text'});
  }

  // save manual renewal from chat room
  manualRenewalFrmChat(postObj, ccode){
    var epurl=LOOKUP_URL+"/scmanualrenewfrchatsc/"+ccode;
    return this.httpClient.put(epurl, postObj, {responseType: "text"});
  }

  // get circle patient details by mobile number

  getCirclePatientDetailsByMobileNumber(mobilenumber)
  {
    let url = LOOKUP_URL+"/getcirclepatientprofile/"+mobilenumber;
    return this.httpClient.get(url, {responseType: "json"});
  }

  // save  otp
  saveOtp(otpobj) {
    var epurl=LOOKUP_URL+"/saveotp";
    return this.httpClient.post(epurl, otpobj, {responseType: 'json'});
  }

  // send otp
  sendOtp(mobilenumber,otp)
  {
    let url = LOOKUP_URL+"/sendotpmsg/"+mobilenumber+"/"+otp;
    return this.httpClient.get(url, {responseType: "json"});
  }

  // Get Otp
  getOtp(mobilenumber)
  {
    let url = LOOKUP_URL+"/getotp/"+mobilenumber;
    return this.httpClient.get(url, {responseType: "json"});
  }

  // Get Otp Record
  getOtpRecord(mobilenumber)
  {
    let url = LOOKUP_URL+"/getotprecord/"+mobilenumber;
    return this.httpClient.get(url, {responseType: "json"});
  }

  // Update New OTP
  updateNewOtp(mobilenumber,status,otp)
  {
    let url = LOOKUP_URL+"/updatenewotp/"+mobilenumber+"/"+status+"/"+otp;
    return this.httpClient.put(url, {responseType: "text"});
  }

  // Update Circle Patient Update New Password
  updateCirclePatientPassword(pass,mobilenumber)
  {
    let url = LOOKUP_URL+"/resetcirclepatientpassword/"+pass+"/"+mobilenumber;
    return this.httpClient.put(url, {responseType: "text"});
  }

  // send push notification to doctor
  sendWebPushNotificationDoctor(notifobj) {
    let url = LOOKUP_URL+"/webpushnotificationdoctor";
    return this.httpClient.post(url, notifobj, {responseType: "text"});
  }

  // Update Patient hand book followup and appointment
  updateHandBookApptPayst(apptobj,st)
  {
    let url = LOOKUP_URL+"/updatehbapptpayst/"+st;
    return this.httpClient.put(url, apptobj, {responseType: "json"});
  }

  // check followup clinical appointment after one week for patient
  checkFollowClinicalApptAfterOneWeek(ptuserid): Observable<any>
  {
    let url = LOOKUP_URL+"/ckfollowapptaow/"+ptuserid;
    return this.httpClient.get(url,  {responseType: "json"});
  }

  // Update Patient followup multiple tag appointment
  updateHandBookTaggedApptPaystByPt(updateObj)
  {
    let url = LOOKUP_URL+"/updatetagapptpayst";
    return this.httpClient.put(url, updateObj, {responseType: "text"});
  }

  // Update Patient followup multiple clinical apptointment 
  updateHandBookFupApptPaystByPt(updateObj)
  {
    let url = LOOKUP_URL+"/updatefupapptpayst";
    return this.httpClient.put(url, updateObj, {responseType: "text"});
  }

  

  // Get Plan Price
  getPlanPrice(planid)
  {
    let url = LOOKUP_URL+"/getplanprice/"+planid;
    return this.httpClient.get(url, {responseType: "json"});
  }

  // Get Scratch Card Details by scratch code
  getScratchCardDetails(scratchcode)
  {
    let url = LOOKUP_URL+"/getscratchcard/"+scratchcode;
    return this.httpClient.get(url, {responseType: "json"});
  }

  // Update Scratch Card Subscription Status
  updateSubscriptionStatusForScratchCard(scratchcode,status)
  {
    let url = LOOKUP_URL+"/updatescratchcardstatus/"+scratchcode+"/"+status;
    return this.httpClient.put(url, {responseType: "text"});
  }

  // Update Scratch Card Status
  updateStatusForScratchCard(scratchcode,status)
  {
    let url = LOOKUP_URL+"/updatescratchstatus/"+scratchcode+"/"+status;
    return this.httpClient.put(url, {responseType: "text"});
  }

  // Update Patient walk through status
  updateWalkthroughStatus(ptprofile)
  {
    let url = LOOKUP_URL+"/updatewtstatus";
    return this.httpClient.put(url, ptprofile, {responseType: "json"});
  }

  // save patient triage history
  saveTriageHistory(postObj){
    var epurl=LOOKUP_URL+"/scsavetriagehistorysc";
    console.log("url......"+epurl);
    return this.httpClient.post(epurl, postObj, {responseType: "text"});
  }

  // update walk through and remember me

  updateRememberWalkThrough(userid,type,value)
  {
    let url = LOOKUP_URL+"/updateremembermewalkthrough/"+userid+"/"+type+"/"+value;
    return this.httpClient.put(url, {responseType: "json"});
  }

  // get all diseas category
  getAllDiseasCategory()
  {
    let url = LOOKUP_URL+"/getallcategory";
    return this.httpClient.get(url, {responseType: "json"});
  }

  // get all diseas sub category by category id
  getAllDiseasSubcategory(dcatid)
  {
    let url = LOOKUP_URL+"/getsubcategorybycid/"+dcatid;
    return this.httpClient.get(url, {responseType: "json"});
  }

  // get covid circle by least number of patient
  getCovidCircleByLestnoPt(obj)
  {
    let url = LOOKUP_URL+"/getcovidcirlceleastpt";
    return this.httpClient.put(url, obj, {responseType: "json"});
  }

  // get covid circle by least number of patient
  getDocProfileSpecRole(ccode)
  {
    let url = LOOKUP_URL+"/getDocprospecrole/"+ccode;
    return this.httpClient.get(url, {responseType: "json"});
  }

  // get packages questions
  getPackageQuestionsByCcodeSubcatid(ccode, subcatid,)
  {
    var epurl=LOOKUP_URL+"/getpackquesbyccodesubcid/"+ccode+"/"+subcatid;
    return this.httpClient.get(epurl, {responseType: 'json'});
  }

  // get packages by cicle code subcategory id
  getPackagesByCcodeSubcatid(ccode, subcatid)
  {
    var epurl=LOOKUP_URL+"/getPackagesbyccodesubcatid/"+ccode+"/"+subcatid;
    return this.httpClient.get(epurl, {responseType: 'json'});
  }

  //	check tagging created for patient by userid ccode docuserid planid
  checkPackageTaggingForPatient(ptuid,planid,docuid,ccode): Observable<any> {
    var epurl=LOOKUP_URL+"/checkpackagetagforpt/"+ptuid+"/"+docuid+"/"+planid+"/"+ccode;
    //console.log("hffy->",epurl);
    return this.httpClient.get(epurl, {responseType: "json"});
  }

  // save followup clinic appt
  createChatPackageCirclePtAndMemDoc(apptList) {
    let url = LOOKUP_URL+"/packageptmemdocchat";
    return this.httpClient.post(url,apptList, {responseType: "json"});
  }

  // get circle obj and  circle doctor list for link join
  getCircleDocListByCcodeNewUIApi(obj) {
    var epurl=LOOKUP_URL+"/ptjoincirclename";
    return this.httpClient.put(epurl, obj, {responseType: "json"});
  }

  // save package video appt
  savePackageVideoAppt(apptList) {
    let url = LOOKUP_URL+"/savepackagevideoappt/ptapp";
    return this.httpClient.put(url,apptList, {responseType: "text"});
  }

  // get package circle video appt
  getPackageVideoAppt(ptUid, circCode, circleType) {
    let url = LOOKUP_URL+"/getptpackvideoappt/"+ptUid+"/"+circCode+"/"+circleType;
    return this.httpClient.get(url,{responseType: "json"});
  }

  // get Doctor RTC Token
  getDoctorRTCToken(doctoruid,channelName)
  {
    var epurl=LOOKUP_URL+"/getrtctokendoctor/"+doctoruid+"/"+channelName;
    return this.httpClient.get(epurl,{responseType: 'text'});
  }

  // get Patient RTC Token
  getPatientRTCToken(patientuid,channelName)
  {
    var epurl=LOOKUP_URL+"/getrtctokenpatient/"+patientuid+"/"+channelName;
    return this.httpClient.get(epurl,{responseType: 'text'});
  }
  // save patient answered ques for subscribed package circle
  savePtAnsQusPackSubscp(qaList)
  {
    var epurl=LOOKUP_URL+"/saveptqa";
    return this.httpClient.post(epurl, qaList, {responseType: 'json'});
  }

  // search circle by search term
  searchCircle(searchobj)
  {
    var epurl=LOOKUP_URL+"/searchcircle";
    return this.httpClient.put(epurl, searchobj, {responseType: 'json'});
  }

  // get circle list by no uid patient home page search
  getClistNoUidPatientSearchHome(obj)
  {
    let url = LOOKUP_URL+"/getshomeclist";
    return this.httpClient.put(url, obj, {responseType: "json"});
  }

  // check promo code valid or not and aready used
  checkPromoCodeValidityAndUsage(userid, promocode)
  {
    let url = LOOKUP_URL+"/checkpcode/"+userid+"/"+promocode;
    return this.httpClient.put(url, {responseType: "json"});
  }

  // get linked packages sub category detail by circle code
  getSubcategoryAndPackList(ccode) {
    let url = LOOKUP_URL+"/getlpsubcat/"+ccode;
    return this.httpClient.get(url, {responseType: "json"});
  }

  // get circle renewal package detail and questions
  getCircleRenewalPackAndQuesList(ccode, subcatid) {
    let url = LOOKUP_URL+"/getrenewalpackques/"+ccode+"/"+subcatid;
    return this.httpClient.get(url, {responseType: "json"});
  }

  getCircleDetailAndPackPlan(ccode, userid, subcatid) {
    let url = LOOKUP_URL+"/getcirclepackplan/"+ccode+"/"+userid+"/"+subcatid;
    return this.httpClient.get(url, {responseType: "json"});
  }

  // get package plan question answer view in chat room
  getPackagePlanQuesAnsDetail(ptuid, ccode, packageid) {
    var epurl=LOOKUP_URL+"/getptpackquesans/"+ccode+"/"+ptuid+"/"+packageid;
    return this.httpClient.get(epurl, {responseType: "json"});
  }
  
  // get package plan question answer view in chat room
  getSingleCircleListWithTimeSlot(obj) {
    var epurl=LOOKUP_URL+"/getclistfrsingconsul";
    return this.httpClient.put(epurl, {responseType: "json"});
  }
  
  // check patient subscribed or not 
  checkPtSubscbHistory(ptuid, ccode) {
    var epurl=LOOKUP_URL+"/sccheckptsubscbhissc/"+ptuid+"/"+ccode;
    return this.httpClient.get(epurl, {responseType: "json"});
  }

  // get patient subscribed circle code list with booked appointment
  getPtCircleListWithBookedAppt(ptuid:any,cdate) {
    let url = LOOKUP_URL+"/scgetptcirclecodelistwithapptsc/"+ptuid+"/"+cdate;
    return this.httpClient.get(url, {responseType: "json"});
  }

  // get circle list for single comdultation
  getCirclListFrSingleconsul(obj) {
    let url = LOOKUP_URL+"/getclistfrsingconsul";
    return this.httpClient.put(url, obj, {responseType: "json"});
  }

  upadtePatientOtpForLogin(mobileno, otp) {
    let url = SERVER_URL+"/updateotp/"+mobileno+"/"+otp;
    // console.log("url", url);
    return this.httpClient.put(url, {responseType: "json"});
  }

  // update patient otp for login
  checkOtpPassValid(mobileno, otp) {
    let url = SERVER_URL+"/checkotp/"+mobileno+"/"+otp;
    // console.log("url", url);
    return this.httpClient.get(url, {responseType: "json"});
  }

   // get ticket by userid
   getTicketByUserid(userid) {
    let url = LOOKUP_URL+"/getticketdetails/"+userid;
    // console.log("url", url);
    return this.httpClient.get(url, {responseType: "json"});
   }

    // get ticket by qr number
   getTicketByQRNumber(qrnumber) {
    let url = LOOKUP_URL+"/getticketdetailsbyqrnumber/"+qrnumber;
    // console.log("url", url);
    return this.httpClient.get(url, {responseType: "json"});
  }

  // Save Enrollment

  saveEnrollment(values:any){
    var epurl=LOOKUP_URL+"/saveenrollment";
    return this.httpClient.post(epurl, values, {responseType: "json"});
  }
  // get all disease sub category list
  getAllDiseaseSubcategoryList() {
    let url = LOOKUP_URL+"/getallsubcategory";
    return this.httpClient.get(url, {responseType: "json"});
  }

  // get circle list for single comdultation
  getCirclListFrSingleconsulByUrl(obj) {
    let url = LOOKUP_URL+"/getclistfrsigconsulurl";
    return this.httpClient.put(url, obj, {responseType: "json"});
  }  

  // get active appt of patient for url single consult and circle list with doctor appt slot
  getPtActApptSingConsulAndClistDocSlotsForUrl(obj) {
    let url = LOOKUP_URL+"/getvdapptfrsigconsulurlclistdocslot";
    return this.httpClient.put(url, obj, {responseType: "json"});
  }

}

