import { Component, OnInit, ViewChild, enableProdMode, ApplicationModule, HostListener } from '@angular/core';
import { ActionSheetController, MenuController, ModalController, NavController, Platform, PopoverController, ToastController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router } from '@angular/router';
import * as firebase from 'firebase/app';
import { AuthService } from './auth.service';
import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx'; 
import { DataService } from './services/data.service';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { LoginAuthService } from './services/login-auth.service';
import { SwasthStorageService } from './storage/swasth-storage.service';
import { AppAvailability } from '@ionic-native/app-availability/ngx';
import { AppLauncher, AppLauncherOptions } from '@ionic-native/app-launcher/ngx';
import { ChatpagePage } from './chatpage/chatpage.page';
import { environment } from '../environments/environment';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { WebsocketService } from './services/websocket.service';



// this is the magic wand
enableProdMode();


// import { Zoom } from '@ionic-native/zoom/ngx';
const config = {
  apiKey: 'AIzaSyBrF9C3m2KSRI7b-ctKLInSaFcVRacZT5Y',
  authDomain: 'ionic-push-demo-6914e.firebaseapp.com',
  databaseURL: 'https://ionic-push-demo-6914e.firebaseio.com',
  projectId: 'ionic-push-demo-6914e',
  storageBucket: 'ionic-push-demo-6914e.appspot.com',
  messagingSenderId: "475705950967",
  appId: "1:475705950967:web:173de019774ed40d4309f2"
};

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {

  // SDK_KEY = 'UgYsDMs3ve5agaE3b9V1fWhSHdwOawz0WDPN';
  // SDK_SECRET = 'LeKWan3ypswDB8aNs3HCT0ixNFva7V3guNkB';
  // set up hardware back button event.
  lastTimeBackPress = 0;
  timePeriodToExit = 2000;

  circlecode: any;
  docuid: any;
  ptuid: any;
  private sub1$: any;
  private resumesub1: any;
  videocallRedirection = false;
  autoRefreshFirstTime = false;
  doctorProfile: any;

  public selectedIndex = 0;
  
  appResume = false;
  notifReceived = false;
  pageResumed = false;
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];

  constructor(
    public platform: Platform, public router: Router,
    public splashScreen: SplashScreen,
    public statusBar: StatusBar,
    public authService: AuthService,
    public firebaseX: FirebaseX,
    public dataService: DataService,
    public androidPermissions: AndroidPermissions,
    public toastCtrl: ToastController,
    public modalCtrl: ModalController,
    public popoverCtrl: PopoverController,
    public actionSheetCtrl: ActionSheetController,
    public menu: MenuController,
    public navCntrl: NavController,
    public loginAuth: LoginAuthService, public swStorage: SwasthStorageService,
    private afMessaging: AngularFireMessaging,
    public webSocket: WebsocketService
    //public zoomService:Zoom
  ) {
    this.initializeApp();
    this.newVideoWebSocket();
    //this.webSocketVideoCall();
    //this.viewDoctorChatMessages("","");
    // this.platform.ready().then(() => {
    //   this.resumesub1 = this.platform.resume.subscribe(() => {
    //     this.swStorage.getStorage(this.loginAuth.LOGINUSER_PROFILE).then(logindata => {
    //       let ptDetail:any = logindata;
    //     //this.webSocket.signPatient(ptDetail.userID);
    //     this.webSocket.subcribe(ptDetail.userID,true);
    //     });
    //     //this.onInit();
    //     //this.ionViewWillEnter();
    //     //window.location.assign('/');
    //     //this.splashScreen.show();
    //     //location.reload();
    // //     //this.router.navigateByUrl("circle-list");
    // //     //this.onInit();
    // //     //this.webSocketVideoCall();
    // //     //alert("page resume");
    // //     //this.webSocketVideoCall();
    // //     // let loginUserDetail: any;
    // //     // let ptDetail: any;
    // //     // this.swStorage.getStorage(this.loginAuth.LOGINUSER_PROFILE).then(logindata => {
    // //     //   loginUserDetail = logindata;
    // //     //   ptDetail = logindata;
    // //     //   if (loginUserDetail != undefined) {
    // //     //   this.webSocket.subcribe(loginUserDetail.userID,false);
    // //     //   }
    // //     // });
    //   });
    // });
  }



  async onInit() {
    //this.webSocketVideoCall();
    this.newVideoWebSocket();    
  }


  initializeApp() {
    this.platform.ready().then(() => {
      // this line for disable side menu swipe
      this.menu.swipeGesture(false);

      // Comment for APK Un Comment for web

      this.platform.backButton.subscribeWithPriority(9999, () => {
        document.addEventListener('backbutton', function (event) {
          event.preventDefault();
          event.stopPropagation();
          console.log('hello');
        }, false);
      });
      
      // this.statusBar.styleDefault();

      // this.platform.backButton.subscribeWithPriority(1, () => { // to disable hardware back button on whole app
      // });
      this.sub1$ = this.platform.resume.subscribe(() => {
        let loginUserDetail: any;
        let ptDetail: any;
        if (this.swStorage.getStorage(this.loginAuth.LOGINUSER_PROFILE)) {
          this.swStorage.getStorage(this.loginAuth.LOGINUSER_PROFILE).then(logindata => {
            loginUserDetail = logindata;
            ptDetail = logindata;
            if (loginUserDetail != undefined) {
              this.authService
                .getAgoraVideoDetailsByPatientuserid(loginUserDetail.userID)
                .subscribe(agoradata => {
                  let agoraData: any = agoradata;
                  if (agoraData != null) {
                    let channel = agoraData.channelName;
                    let meetLink = agoraData.meetingLink;
                    let circleCode = agoraData.circleCode;
                    let ptuid = agoraData.patientUserID;
                    let docuserid = agoraData.doctorUserID;
                    let patientRTCToken=agoraData.patientRTCToken;
                    if (agoraData.status == "Active") {
                      this.videocallRedirection = true;
                      setTimeout(() => {
                        this.router.navigate(['/joincall'], { queryParams: { channel, meetLink, circleCode, ptuid, docuserid } });
                      }, 1000);
                      // setTimeout(() => {
                      //   this.router.navigate(["video", { circleCode: agoraData.circleCode, ptuid: agoraData.patientUserID, docuid: agoraData.doctorUserID }]);
                      // }, 500);
                      setTimeout(() => {
                        this.videocallRedirection = false;
                      }, 5000);
                    }
                    else {
                      console.log("Call Missed");
                    }
                  }
                  else {
                    console.log("Call not active");
                  }

                })
            }
          });
        }

      });

      // this.router.navigateByUrl('login');
      //this.statusBar.styleDefault();
      this.statusBar.styleLightContent();
      // this.splashScreen.show();
      // this.splashScreen.hide();
      

      this.androidPermissions.requestPermissions(
        [
          this.androidPermissions.PERMISSION.CAMERA,
          this.androidPermissions.PERMISSION.CALL_PHONE,
          this.androidPermissions.PERMISSION.GET_ACCOUNTS,
          this.androidPermissions.PERMISSION.READ_EXTERNAL_STORAGE,
          this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE,
          this.androidPermissions.PERMISSION.RECORD_AUDIO
        ]
      );



      /*this.zoomService.initialize(this.SDK_KEY, this.SDK_SECRET)
      .then((success: any) => console.log(success))
      .catch((error: any) => console.log(error));*/

      this.firebaseX
        .getToken()
        .then(token => {
          console.log(`The token is ${token}`);
          //alert(token);
        }) // save the token server-side and use it to push notifications to this device
        .catch(error =>
          console.error('Error getting token', error)
        );



      this.afMessaging.messages
        .subscribe((payload) => {
          let webNotifData: any = payload;
          //console.log('Message received: ', webNotifData.data);
          this.circlecode = webNotifData.data.circleCode;
          this.docuid = webNotifData.data.docUid;
          this.ptuid = webNotifData.data.ptUid;
          if (this.videocallRedirection == false) {
            if (webNotifData.data.landing_page == "video") {
              let loginUserDetail: any;
              let ptDetail: any
              this.swStorage.getStorage(this.loginAuth.LOGINUSER_PROFILE).then(logindata => {
                loginUserDetail = logindata;
                ptDetail = logindata;
                this.authService
                  .getAgoraVideoDetailsByPatientuserid(loginUserDetail.userID)
                  .subscribe(agoradata => {
                    let agoraData: any = agoradata;
                    if (agoraData != null) {
                      let channel = agoraData.channelName;
                      let meetLink = agoraData.meetingLink;
                      let circleCode = agoraData.circleCode;
                      let ptuid = agoraData.patientUserID;
                      let docuserid = agoraData.doctorUserID;
                      let patientRTCToken=agoraData.patientRTCToken;
                      if (agoraData.status == "Active") {
                        //this.presentToast("Incoming Call");
                        this.videocallRedirection = true;
                        setTimeout(() => {
                          this.router.navigate(['/joincall'], { queryParams: { channel, meetLink, circleCode, ptuid, docuserid } });
                        }, 1000);
                        // setTimeout(() => {
                        //   this.router.navigate(["video", { circleCode: agoraData.circleCode, ptuid: agoraData.patientUserID, docuid: agoraData.doctorUserID }]);
                        // }, 500);
                        setTimeout(() => {
                          this.videocallRedirection = false;
                        }, 5000);
                      }
                    }
                    else {
                    }


                  })

              });


            }
          }

        });

      this.firebaseX
        .onMessageReceived()
        .subscribe(data => {
          //alert("Message Reveived");
          this.notifReceived = true;
          this.circlecode = data.circleCode;
          this.docuid = data.docUid;
          this.ptuid = data.ptUid;
          if (data.landing_page == "video") {
            let loginUserDetail: any;
            let ptDetail: any
            this.swStorage.getStorage(this.loginAuth.LOGINUSER_PROFILE).then(logindata => {
              loginUserDetail = logindata;
              ptDetail = logindata;
              this.authService
                .getAgoraVideoDetailsByPatientuserid(loginUserDetail.userID)
                .subscribe(agoradata => {
                  let agoraData: any = agoradata;

                  if (agoraData != null) {
                    let channel = agoraData.channelName;
                    let meetLink = agoraData.meetingLink;
                    let circleCode = agoraData.circleCode;
                    let ptuid = agoraData.patientUserID;
                    let docuserid = agoraData.doctorUserID;
                    let patientRTCToken=agoraData.patientRTCToken;
                    if (agoraData.status == "Active") {
                      //this.presentToast("Incoming Call");
                      setTimeout(() => {
                        this.router.navigate(['/joincall'], { queryParams: { channel, meetLink, circleCode, ptuid, docuserid } });
                      }, 500);
                      // setTimeout(() => {
                      //   this.router.navigate(["video", { circleCode: agoraData.circleCode, ptuid: agoraData.patientUserID, docuid: agoraData.doctorUserID }]);
                      // }, 500);
                      setTimeout(() => {
                        this.videocallRedirection = false;
                        this.notifReceived = true;
                      }, 5000);
                    }
                  }
                  else {
                    alert("Call Missed");
                  }


                })

            });
          }
          if (data['tap']) {
            let pageurl = this.router.url;
            let currentPage = pageurl.split('/');
            let ctRoom: any = {
              docuid: data.docUid,
              circleCode: data.circleCode,
              ptuid: data.ptUid,
              docStatus: "Active",
              pageType: "push"
            };
            let loginUserDetail: any;
            let ptDetail: any
            this.swStorage.getStorage(this.loginAuth.LOGINUSER_PROFILE).then(logindata => {
              loginUserDetail = logindata;
              ptDetail = logindata;
              this.authService
                .getPtSingleCircle(data.circleCode, data.ptUid)
                .subscribe((circdata) => {
                  if (circdata != null) {
                    let circ: any = circdata;
                    let chatObj: any = {
                      userID: data.ptUid,
                      circleCode: data.circleCode
                    }
                    this.authService
                      .getChatRestrictDetail(chatObj)
                      .subscribe(restData => {
                        let chatDetail: any = restData;
                        if (currentPage[1] == "chatroom") {
                          const chatRoomPause = JSON.parse(localStorage.getItem('ctRoomPause'));
                          if (chatRoomPause) {
                            if (chatRoomPause.circleCode == data.circleCode && chatRoomPause.docUid == data.docUid) {
                              // this.navCntrl.back();
                            } else {
                              this.navCntrl.back();
                            }
                          }
                        }
                        //console.log("circ detail", circ);
                        ctRoom.circleDetail = circ;
                        ctRoom.loginUserDetail = loginUserDetail;
                        ctRoom.ptDetail = ptDetail;
                        ctRoom.chatRestrict = chatDetail;
                        if (data.landing_page == "chatroom") {
                          this.dataService.setData(29, ctRoom);
                          localStorage.setItem('ctRoom' + data.circleCode, JSON.stringify(ctRoom));
                          setTimeout(() => {
                            this.router.navigate(["chatroom/29", { circleCode: data.circleCode }]);
                          }, 500);
                        }
                      });
                  }
                });
            });
          }
          //}
          // if (data.wasTapped) {
          //   let ctRoom = {
          //     docuid: data.docUid,
          //     circleCode: data.circleCode,
          //     ptuid: data.ptUid
          //   };
          //   if(data.landing_page=="chatroom") {
          //     this.dataService.setData(29, ctRoom); 
          //     localStorage.setItem('ctRoom' + data.circleCode, JSON.stringify(ctRoom));
          //     this.router.navigate(["chatroom/29", { circleCode: data.circleCode }]);  
          //   }
          // } 
          // else {
          //   let ctRoom = {
          //     docuid: data.docUid,
          //     circleCode: data.circleCode,
          //     ptuid: data.ptUid
          //   };
          //   if(data.landing_page=="chatroom") {
          //     this.dataService.setData(29, ctRoom); 
          //     localStorage.setItem('ctRoom' + data.circleCode, JSON.stringify(ctRoom));
          //     this.router.navigate(["chatroom/29", { circleCode: data.circleCode }]);
          //   }
          // }
        });

      this.firebaseX
        .onTokenRefresh()
        .subscribe((token: string) =>
          console.log(`Got a new token ${token}`)
        );
    });

    firebase.initializeApp(config);
    //firebase.initializeApp(environment.firebaseConfig);

    this.platform.backButton.subscribeWithPriority(10, async () => {
      try {
        const element = await this.actionSheetCtrl.getTop();
        if (element) {
          element.dismiss();
          return;
        }
      } catch (error) {
      }

      // close popover
      try {
        const element = await this.popoverCtrl.getTop();
        if (element) {
          element.dismiss();
          return;
        }
      } catch (error) {
      }

      // close modal
      try {
        const element = await this.modalCtrl.getTop();
        if (element) {
          element.dismiss();
          return;
        }
      } catch (error) {
        console.log(error);

      }

      // close side menua
      try {
        const element = await this.menu.getOpen();
        if (element) {
          this.menu.close();
          return;

        }

      } catch (error) {

      }

      try {
        let pageurl = this.router.url;
        let currentPage = pageurl.split('/');
        if (currentPage[1] == "circle-list" || currentPage[1] == "specialists" || currentPage[1] =="health-locker-prescription" || currentPage[1] =="pt-profile") {
          if (new Date().getTime() - this.lastTimeBackPress < this.timePeriodToExit) {
            // this.platform.exitApp(); // Exit from app
            navigator['app'].exitApp(); // work for ionic 4
          } else {
            const toast = await this.toastCtrl.create(
              {
                message: 'Press back again to exit App.',
                duration: 1000,
                position: 'bottom'
              });
            toast.present();
            this.lastTimeBackPress = new Date().getTime();
          }
        } else if (currentPage[1] != "select-virtual-hospital" && currentPage[1] != "health-locker-prescription" && currentPage[1] != "pt-profile") {
          this.navCntrl.back();
        }

      } catch {

      }

    });
  }

  // ionViewDidLeave() {    
  //   this.sub1$.unsubscribe();
  // }

  ionViewDidLeave() {
    this.resumesub1.unsubscribe();
  }

  ionViewWillEnter() {
    //this.webSocketVideoCall();
  }

  // webSocketVideoCall() {
  //   this.webSocket.videocall.subscribe((data) => {
  //     if (data && this.videocallRedirection == false && this.autoRefreshFirstTime == false && this.notifReceived == false) {
  //       this.autoRefreshFirstTime = true;
  //       console.log("Video Call Web Socket==>", data);
  //       let loginUserDetail: any;
  //       let ptDetail: any;
  //       setTimeout(() => {
  //         this.swStorage.getStorage(this.loginAuth.LOGINUSER_PROFILE).then(logindata => {
  //           loginUserDetail = logindata;
  //           ptDetail = logindata;
  //           this.authService
  //             .getAgoraVideoDetailsByPatientuserid(loginUserDetail.userID)
  //             .subscribe(agoradata => {
  //               let agoraData: any = agoradata;
  //               if (agoraData != null) {
  //                 if (agoraData.status == "Active") {
  //                   this.presentToast("Incoming Call");
  //                   this.videocallRedirection = true;
  //                   setTimeout(() => {
  //                     this.router.navigate(["video", { circleCode: data.circlecode, ptuid: data.ptuid, docuid: data.docuid }]);
  //                   }, 500);
  //                   setTimeout(() => {
  //                     this.videocallRedirection = false;
  //                     this.autoRefreshFirstTime = false;
  //                   }, 5000);
  //                 }
  //               }
  //               else {
  //                 alert("Call Missed");
  //               }
  //             });
  //         });
  //       }, 1000);

  //     }
  //   });
  // }

  newVideoWebSocket() {
    this.webSocket.videocall.subscribe((data) => {
      //alert(this.videocallRedirection+","+this.appResume+","+this.autoRefreshFirstTime+","+this.notifReceived);
      if (data && this.videocallRedirection == false && this.autoRefreshFirstTime == false && this.notifReceived == false) {
        this.autoRefreshFirstTime = true;
        // console.log("Video Call Web Socket==>", data);
        // let meetLink = data.meetlink;
        // let channel = data.channel;
        // let circlecode = data.circlecode;
        // let ptuserid = data.ptuid;
        // let docuserid = data.docuid;
        // let patientRTCToken=data.patientRTCToken;
        this.authService
          .getDoctorProfile(data.docuid)
          .subscribe(data => {
            this.doctorProfile = data;
            // console.log("Doctor Profile==>", this.doctorProfile);
          });
        // setTimeout(() => {
        //   this.router.navigate(["waiting-room", { circleCode: data.circlecode, ptuid: data.ptuid, docuid: data.docuid }]);
        //   this.answerVideoCall(data.circlecode, data.ptuid, data.docuid, meetLink, channel);
        // }, 500);

        this.authService
          .getAgoraVideoDetailsByPatientuserid(data.ptuid)
          .subscribe(agoradata => {
            let agoraData: any = agoradata;
            console.log("Agora Data==>",agoraData);
            if (agoraData != null) {
              let channel = agoraData.channelName;
              let meetLink = agoraData.meetingLink;
              let circleCode = agoraData.circleCode;
              let ptuid = agoraData.patientUserID;
              let docuserid = agoraData.doctorUserID;
              let patientRTCToken=agoraData.patientRTCToken;
              if (agoraData.status == "Active") {
                // this.presentToast("Incoming Call");
                this.videocallRedirection = true;
                setTimeout(() => {
                  this.router.navigate(['/joincall'], { queryParams: { channel, meetLink, circleCode, ptuid, docuserid } });
                }, 2000);
              }
              else {
                alert("Call Missed");
              }
            }
          });

        setTimeout(() => {
          this.videocallRedirection = false;
          this.autoRefreshFirstTime = false;
        }, 5000);

      }
    });
  }

  

  private async presentToast(message) {
    const toast = await this.toastCtrl.create({
      message,
      duration: 1000
    });
    toast.present();
  }

  async viewDoctorChatMessages(circlecode, ptuid, docuid) {
    const popover = await this.popoverCtrl.create({
      component: ChatpagePage,
      componentProps: { circlecode: circlecode, ptuid: ptuid, docuid: docuid },
      cssClass: 'my-custom-class',
      translucent: true
    });
    await popover.present();

    const { role } = await popover.onDidDismiss();
    // console.log('onDidDismiss resolved with role', role);
  }

  ngOnInit() {
    this.platform.ready().then(() => {
      this.resumesub1 = this.platform.resume.subscribe(() => {
        this.swStorage.getStorage(this.loginAuth.LOGINUSER_PROFILE).then(logindata => {
          let ptDetail: any = logindata;
          //this.webSocket.signPatient(ptDetail.userID);
          //alert(ptDetail.userID);
          this.webSocket.subcribe(ptDetail.userID, true);
        });
      });
    });
    this.authService.logout();
    //this.viewDoctorChatMessages();
    /* const path = window.location.pathname.split('folder/')[1];
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    } */
  }

  // @HostListener('window:beforeunload', ['$event'])
  //  onWindowClose(event: any): void {
  //   this.swStorage.getStorage(this.loginAuth.LOGINUSER_PROFILE).then(pdata => {
  //     if(pdata != null){
  //       if(pdata.rememberMe == 'no') {
  //         this.loginAuth.logout();
  //       }
  //     }
  //   });
  //   // Do something    
  //   event.preventDefault();
  //   event.returnValue = false;

  // }


}
