//Image URLS

export const S3BASE_URL="https://document.swasth.online/";
export const HEADER_LOGO = "https://swasth.online/logo-swasth-icon.svg";
export const SITE_URL = "https://www.swasth.online/";
export const SWASTH_URL = "https://www.swasth.online/";
export const IMAGEBUCKETURL = "https://swasthprescription.s3-ap-southeast-1.amazonaws.com/";
// export const RAZORPAYMODE="Test";
export const RAZORPAYMODE="Live";
//export const SITE_URL = "http://localhost:8100/#/";
export const EXPIRYDISCLAIMERSTS = false;
export const DEVICETOKEN="";


//Server SSL

export const SERVER_URL = "https://api.swasth.online/auth";
export const PATIENT_URL = "https://api.swasth.online/circlepatient";
export const DOCTOR_URL = "https://api.swasth.online/fldoctor";
export const NURSE_URL = "https://api.swasth.online/nurse"; 
export const DX_URL = "https://api.swasth.online/dxcenter";
export const PHARMACY_URL = "https://api.swasth.online/pharmacy";
export const MEDICINE_URL = "https://api.swasth.online/prescription";
export const LOOKUP_URL="https://api.swasth.online/circlelookup";
export const VITAL_URL = "https://api.swasth.online/circlevital";
export const RECP_URL = "https://api.swasth.online/recp";
export const PRESC_TEMPLATE = "https://api.swasth.online/presctemplate";
export const SEND_MEDICINE = "https://api.swasth.online/pharsend";
export const SEND_DXCENTER = "https://api.swasth.online/dxsend";
export const SEND_NURSE = "https://api.swasth.online/sendnurse";
export const SEND_DOCTOR = "https://api.swasth.online/senddoctor"; 
export const APPOINTMENT_URL = "https://api.swasth.online/appt";     
export const UPLOAD_URL = "https://api.swasth.online/circleupload";
export const DOWNLOAD_URL = "https://api.swasth.online/circledownload";
export const HOSPITAL_URL="https://api.swasth.online/flhospital";
export const PYTHON_URL = "https://api.swasth.online/python/getailment";

//New Server With SSl

//export const SERVER_URL = "https://abc.swasth.online/auth";
// export const PATIENT_URL = "https://abc.swasth.online/patient";
//export const DOCTOR_URL = "https://abc.swasth.online/doctor";
// export const NURSE_URL = "https://abc.swasth.online/nurse";
// export const DX_URL = "https://abc.swasth.online/dxcenter";
// export const PHARMACY_URL = "https://abc.swasth.online/pharmacy";
// export const MEDICINE_URL = "https://abc.swasth.online/prescription";
//export const LOOKUP_URL="https://abc.swasth.online/lookup";
// export const VITAL_URL = "https://abc.swasth.online/vital";
// export const RECP_URL = "https://abc.swasth.online/recp";
// export const PRESC_TEMPLATE = "https://abc.swasth.online/presctemplate";
// export const SEND_MEDICINE = "https://abc.swasth.online/pharsend";
// export const SEND_DXCENTER = "https://abc.swasth.online/dxsend";
// export const SEND_NURSE = "https://abc.swasth.online/sendnurse";
// export const SEND_DOCTOR = "https://abc.swasth.online/senddoctor"; 
// export const APPOINTMENT_URL = "https://abc.swasth.online/appt"; 
// export const UPLOAD_URL = "https://abc.swasth.online/upload";
// export const DOWNLOAD_URL = "https://abc.swasth.online/download";
// export const HOSPITAL_URL="https://abc.swasth.online/hospital";

//Server Without SSl
//export const SERVER_URL = "http://api.swasth.online/auth";
//export const PATIENT_URL = "http://api.swasth.online/patient";
//export const DOCTOR_URL = "http://api.swasth.online/doctor";
//export const NURSE_URL = "http://api.swasth.online/nurse"; 
//export const DX_URL = "http://api.swasth.online/dxcenter";
//export const PHARMACY_URL = "http://api.swasth.online/pharmacy";
// export const MEDICINE_URL = "http://api.swasth.online/prescription";
//export const LOOKUP_URL="http://api.swasth.online/lookup";
//export const VITAL_URL = "http://api.swasth.online/vital";
//export const RECP_URL = "http://api.swasth.online/recp";
//export const PRESC_TEMPLATE = "http://api.swasth.online/presctemplate";
//export const SEND_MEDICINE = "http://api.swasth.online/pharsend";
//export const SEND_DXCENTER = "http://api.swasth.online/dxsend";
//export const SEND_NURSE = "http://api.swasth.online/sendnurse";
//export const SEND_DOCTOR = "http://api.swasth.online/senddoctor"; 
// export const APPOINTMENT_URL = "http://api.swasth.online/appt"; 
//export const UPLOAD_URL = "http://api.swasth.online/upload";
//export const DOWNLOAD_URL = "http://api.swasth.online/download";
//export const HOSPITAL_URL="http://api.swasth.online/hospital";  

// //Local Room
// export const SERVER_URL = "http://172.16.100.148:5052/auth";
// export const PATIENT_URL = "http://172.16.100.148:5077/circlepatient";
// export const DOCTOR_URL = "http://172.16.100.148:5080/fldoctor";
// export const NURSE_URL = "http://172.16.100.148:5080/flnurse"; 
// export const DX_URL = "http://172.16.100.148:/dxcenter";
// export const PHARMACY_URL = "http://172.16.100.148:/pharmacy";
// export const MEDICINE_URL = "http://172.16.100.148:5058/prescription";
// export const LOOKUP_URL="http://172.16.100.148:5077/circlelookup";
// export const VITAL_URL = "http://172.16.100.148:5077/circlevital";
// export const RECP_URL = "http://172.16.100.148:5080/flrecp";
// export const PRESC_TEMPLATE = "http://172.16.100.148:5058/presctemplate";
// export const SEND_MEDICINE = "http://172.16.100.148:/pharsend";
// export const SEND_DXCENTER = "http://172.16.100.148:/dxsend";
// export const SEND_NURSE = "http://172.16.100.148:/sendnurse";
// export const SEND_DOCTOR = "http://172.16.100.148:/senddoctor"; 
// export const APPOINTMENT_URL = "http://172.16.100.148:5080/flappt";     
// export const UPLOAD_URL = "http://172.16.100.148:5077/circleupload";
// export const DOWNLOAD_URL = "http://172.16.100.148:5077/circledownload";
// export const HOSPITAL_URL="http://172.16.100.148:5080/flhospital";
// export const PYTHON_URL = "http://172.16.100.148:/python/getailment"; 

// Server Single Service
// export const SERVER_URL = "https://api.swasth.online/auth";
// export const PATIENT_URL = "https://api.swasth.online/circlepatient";
// export const DOCTOR_URL = "https://api.swasth.online/circledoctor";
// export const HOSPITAL_URL = "https://api.swasth.online/circlehospital";
// export const NURSE_URL = "https://api.swasth.online/nurse"; 
// export const DX_URL = "https://api.swasth.online/dxcenter";
// export const PHARMACY_URL = "https://api.swasth.online/pharmacy";
// export const MEDICINE_URL = "https://api.swasth.online/circleprescription";
// export const LOOKUP_URL="https://api.swasth.online/circlelookup";
// export const VITAL_URL = "https://api.swasth.online/circlevital";
// export const RECP_URL = "https://api.swasth.online/recp";
// export const PRESC_TEMPLATE = "https://api.swasth.online/circlepresctemplate";
// export const SEND_MEDICINE = "https://api.swasth.online/pharsend";
// export const SEND_DXCENTER = "https://api.swasth.online/dxsend";
// export const SEND_NURSE = "https://api.swasth.online/sendnurse";
// export const SEND_DOCTOR = "https://api.swasth.online/senddoctor";
// export const APPOINTMENT_URL = "https://api.swasth.online/appt"; 
// export const UPLOAD_URL = "https://api.swasth.online/circleupload";
// export const DOWNLOAD_URL = "https://api.swasth.online/circledownload";
// export const PAYMENT_URL = "https://api.swasth.online/payment";
// export const PYTHON_URL = "https://api.swasth.online/python/getailment";
