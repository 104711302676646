import { Injectable,OnInit,OnDestroy} from '@angular/core';
import { Subject } from 'rxjs';
import { webSocket} from 'rxjs/webSocket';
import { takeUntil } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class WebsocketService implements OnDestroy{

  subject ;  
  destroy : Subject<boolean> = new Subject<boolean>();
  messageDoctor : Subject<any> = new Subject<any>();
  confirm : Subject<any> = new Subject<any>();
  pres : Subject<any> = new Subject<any>();
  preswithbill : Subject<any> = new Subject<any>();
  onlybill : Subject<any> = new Subject<any>();
  doctorTriage : Subject<any> = new Subject<any>();
  chatcomplete:Subject<any>=new Subject<any>();
  videocall:Subject<any>=new Subject<any>();
  apptCompleted:Subject<any>=new Subject<any>();
  doctorEndCall:Subject<any>=new Subject<any>();
  timeout: any;
  constructor() { }


  ngOnDestroy(): void {
    this.destroy.next(true);
    this.subject.complete();
    this.timeout = null;
  }
  
  

  subcribe(patientId,firstTime) {
    if (firstTime || !this.subject){
      this.destroy.next(false);
      this.subject = webSocket(
        // "ws://localhost:8889/" + patientId + ",patient");
        "wss://autorefresh.swasth.online/" + patientId + ",patient");
      } 

      this.timeout =  setInterval(() => {
        this.subject.next({"data" : {"type" : "ping","msg" : "ping" } });
      }, 58000); 

      //const ws = new WebSocket("ws://localhost:8889/");
      //const ws = new WebSocket("wss://autorefresh.swasth.online/");
      //setWsHeartbeat(ws, 'ping');
      this.subject.pipe(takeUntil(this.destroy)).subscribe(
        (data) => {
          if (data){
            if (data.type == "joinNotification"){
              if (data.msg){
                // console.log('msg recieved');
                this.messageDoctor.next(data.msg);
              }
              else if(data.chatStatus)
              {
                // console.log('msg recieved');
                this.chatcomplete.next(data.chatStatus);
              }
              else {
                // console.log('confirmation recieved');
                this.confirm.next(data);
              }
            }
            else if (data.type == "pres"){
              if(data.status=="onlyprescription")
              {
                this.pres.next(true);
              }
              if(data.status=="prescriptionwithbill")
              {
                this.preswithbill.next(true);
              }
              if(data.status=="onlybill")
              {
                this.onlybill.next(data);
              }
              
            }
            else if (data.type == "triageDoctorToPatient"){
              this.doctorTriage.next(data);
            }
            else if(data.type=="video")
            {
              this.videocall.next(data);
            }
            else if(data.apptType=="completed")
            {
              //console.log("Appointment Completed",data);
              this.apptCompleted.next(data);
            }
            else if(data.calltype=='doctorendcall')
            {
            this.doctorEndCall.next(data);
            }
          }
          // console.log(data);
        },
        (err) => {
          //console.log(err);
        }
      );
    
  }


  signPatient(patientId) {
    this.subject.next(patientId);
  }


  sendNotificationToDoctor(doctorId) {
    // console.log("Subscribe Patient",doctorId);
    this.subject.next({"sentBy" : 'patient',"userId" : doctorId,"data" : {"type" : "joinNotification"} });
  }

  sendMessageToDoctor(doctorId,msg){
    this.subject.next({"sentBy" : 'patient',"userId" : doctorId,"data" : {"type" : "joinNotification","msg" : msg } });
  }

  sendApptBookingConfirmationToDoctor(patientuid,doctoruid,apptdate,appttime,circlecode){
    this.subject.next({"sentBy" : 'patient',"userId" : doctoruid,"data" : {"type" : "appointment","patientuid" : patientuid,"apptdate":apptdate,"appttime":appttime,"circlecode":circlecode } });
  }
  sendVideoCallCutNotificationToDoctor(doctoruid,patientuid,circlecode,type)
  {
    // console.log(doctoruid,patientuid,circlecode,type);
    this.subject.next({"sentBy":"patient","userId":doctoruid,"data":{"type":"videocallend","docuid":doctoruid,"ptuid":patientuid,"circlecode":circlecode,"calltype":type}})
  }

}
