import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { DataResolverService } from './resolver/data-resolver.service';

import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { NgxAgoraSdkNgModule } from 'ngx-agora-sdk-ng';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { MeetingPreviewComponent } from './pages/meeting-preview/meeting-preview.component';
import {
  RoundTranparentIconButtonComponent
} from './shared/components/round-tranparent-icon-button/round-tranparent-icon-button.component';
import { InputOutputSettingsComponent } from './shared/components/input-output-settings/input-output-settings.component';
import { SoundMeterComponent } from './shared/components/sound-meter/sound-meter.component';
import { CameraPreviewComponent } from './shared/components/camera-preview/camera-preview.component';
import { WelcomeComponent } from './pages/welcome/welcome.component';
import { MeetingPageComponent } from './pages/meeting-page/meeting-page.component';
import { AgoraVideoPlayerDirective } from './shared/directives/agora-video-player.directive';
import { MeetingControlsComponent } from './shared/components/meeting-controls/meeting-controls.component';
import { MeetingParticipantComponent } from './shared/components/meeting-participant/meeting-participant.component';
import { MeetingParticipantControlsComponent } from './shared/components/meeting-participant-controls/meeting-participant-controls.component';
import { SoundVisualizerComponent } from './shared/components/sound-visualizer/sound-visualizer.component';
import { SimpleLoadingStrategyService } from './simple-loading-strategy.service';

const routes: Routes = [
  {
    path: '',
    //redirectTo:'testvideocall',
    redirectTo: 'circle-list',
    // redirectTo: 'doc-engmt-pre-form',
    pathMatch: 'full'
  },
  // {
  //   path: '',
  //   redirectTo: 'home',
  //   pathMatch: 'full'
  // },
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'select-virtual-hospital',
    loadChildren: () => import('./select-virtual-hospital/select-virtual-hospital.module').then( m => m.SelectVirtualHospitalPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'search-virtual-hospital/:id',
    resolve: {
      svData: DataResolverService
    },
    loadChildren: () => import('./search-virtual-hospital/search-virtual-hospital.module').then( m => m.SearchVirtualHospitalPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'subscription-page/:id',
    resolve: {
      sbData: DataResolverService
    },
    loadChildren: () => import('./subscription-page/subscription-page.module').then( m => m.SubscriptionPagePageModule),
    // canActivate: [AuthGuard]
  },
  {
    path: 'subscribed-doctor-circle/:id',
    resolve: {
      circleData: DataResolverService
    },
    loadChildren: () => import('./subscribed-doctor-circle/subscribed-doctor-circle.module').then( m => m.SubscribedDoctorCirclePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'virtual-clinic-detail/:id',
    resolve: {
      vcData: DataResolverService
    },
    loadChildren: () => import('./virtual-clinic-detail/virtual-clinic-detail.module').then( m => m.VirtualClinicDetailPageModule),
    // canActivate: [AuthGuard]
  },
  {
    path: 'pt-profile',
    loadChildren: () => import('./pt-profile/pt-profile.module').then( m => m.PtProfilePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'health-locker-prescription/:id',
    resolve: {
      cDetail: DataResolverService
    },        
    loadChildren: () => import('./health-locker-prescription/health-locker-prescription.module').then( m => m.HealthLockerPrescriptionPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'pt-eprescription/:id',
    resolve: {
      dxData: DataResolverService
    },
    loadChildren: () => import('./pt-eprescription/pt-eprescription.module').then( m => m.PtEPrescriptionPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'chatroom/:id',
    resolve: {
      ctData: DataResolverService
    },
    loadChildren: () => import('./chatroom/chatroom.module').then( m => m.ChatroomPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'getmobilenumber',
    loadChildren: () => import('./getmobilenumber/getmobilenumber.module').then( m => m.GetmobilenumberPageModule)
  },
  {
    path: 'familymembers',
    loadChildren: () => import('./familymembers/familymembers.module').then( m => m.FamilymembersPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'addfamilymember',
    loadChildren: () => import('./addfamilymember/addfamilymember.module').then( m => m.AddfamilymemberPageModule),
    canActivate: [AuthGuard]
  },  
  {
    path: 'share-circle',
    loadChildren: () => import('./share-circle/share-circle.module').then( m => m.ShareCirclePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'subscribe-family-member',
    loadChildren: () => import('./subscribe-family-member/subscribe-family-member.module').then( m => m.SubscribeFamilyMemberPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'send-triage-doc-complaint',
    loadChildren: () => import('./send-triage-doc-complaint/send-triage-doc-complaint.module').then( m => m.SendTriageDocComplaintPageModule)
  },
  {
    path: 'circle-list',
    loadChildren: () => import('./circle-list/circle-list.module').then( m => m.CircleListPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'circle-doctors-detail/:id',
    resolve: {
      cDocData: DataResolverService
    },
    loadChildren: () => import('./circle-doctors-detail/circle-doctors-detail.module').then( m => m.CircleDoctorsDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'manage-subscription',
    loadChildren: () => import('./manage-subscription/manage-subscription.module').then( m => m.ManageSubscriptionPageModule)
  },
  {
    path: 'razorpaycheck',
    loadChildren: () => import('./razorpaycheck/razorpaycheck.module').then( m => m.RazorpaycheckPageModule)
  },
  {
    path: 'view-image',
    loadChildren: () => import('./view-image/view-image.module').then( m => m.ViewImagePageModule)
  },
  {
    path: 'preview-gallery-image',
    loadChildren: () => import('./preview-gallery-image/preview-gallery-image.module').then( m => m.PreviewGalleryImagePageModule)
  },
  {
    path: 'manual-renewal',
    loadChildren: () => import('./manual-renewal/manual-renewal.module').then( m => m.ManualRenewalPageModule)
  },
  {
    path: 'covid-complaint/:id',
    resolve: {
      covidFormData: DataResolverService
    },
    loadChildren: () => import('./covid-complaint/covid-complaint.module').then( m => m.CovidComplaintPageModule)
  },
  {
    path: 'view-covid-complaint',
    loadChildren: () => import('./view-covid-complaint/view-covid-complaint.module').then( m => m.ViewCovidComplaintPageModule)
  },
  {
    path: 'change-password',
    loadChildren: () => import('./change-password/change-password.module').then( m => m.ChangePasswordPageModule)
  },
  {
    path: 'renewal-subscription/:id',
    resolve: {
      subscpData: DataResolverService
    },
    loadChildren: () => import('./renewal-subscription/renewal-subscription.module').then( m => m.RenewalSubscriptionPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'chat-room-file-upload',
    loadChildren: () => import('./chat-room-file-upload/chat-room-file-upload.module').then( m => m.ChatRoomFileUploadPageModule)
  },
  {
    path: 'send-chat-complaints',
    loadChildren: () => import('./send-chat-complaints/send-chat-complaints.module').then( m => m.SendChatComplaintsPageModule)
  },
  {
    path: 'healthlocker-tabs',
    loadChildren: () => import('./healthlocker-tabs/healthlocker-tabs.module').then( m => m.HealthlockerTabsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'pt-uploaded-docs',
    loadChildren: () => import('./pt-uploaded-docs/pt-uploaded-docs.module').then( m => m.PtUploadedDocsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'upload-docs/:id',
    resolve: {
      imgData: DataResolverService
    },    
    loadChildren: () => import('./upload-docs/upload-docs.module').then( m => m.UploadDocsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'bills',
    loadChildren: () => import('./bills/bills.module').then( m => m.BillsPageModule)
  },
  {
    path: 'view-uploaded-docs/:id',
    resolve: {
      viewDocs: DataResolverService
    },
    loadChildren: () => import('./view-uploaded-docs/view-uploaded-docs.module').then( m => m.ViewUploadedDocsPageModule),
    // canActivate: [AuthGuard]
  },
  {
    path: 'cs',
    loadChildren: () => import('./cs/cs.module').then( m => m.CsPageModule)
  },
  {
    path: 'open-play-store',
    loadChildren: () => import('./open-play-store/open-play-store.module').then( m => m.OpenPlayStorePageModule)
  },
  {
    path: 'followup-handbook',
    loadChildren: () => import('./followup-handbook/followup-handbook.module').then( m => m.FollowupHandbookPageModule)
  },
  {
    path: 'corporate-login',
    loadChildren: () => import('./corporate-login/corporate-login.module').then( m => m.CorporateLoginPageModule)
  },
  {
    path: 'corporate-signup',
    loadChildren: () => import('./corporate-signup/corporate-signup.module').then( m => m.CorporateSignupPageModule)
  },
  {
    path: 'chatpage',
    loadChildren: () => import('./chatpage/chatpage.module').then( m => m.ChatpagePageModule)
  },
  {
    path: 'corporate-family-member',
    loadChildren: () => import('./corporate-family-member/corporate-family-member.module').then( m => m.CorporateFamilyMemberPageModule)
  },  
  {
    path: 'renewal-circle/:id',
    resolve: {
      crenewData: DataResolverService
    },
    loadChildren: () => import('./renewal-circle/renewal-circle.module').then( m => m.RenewalCirclePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'doc-engmt-pre-form',
    loadChildren: () => import('./doc-engmt-pre-form/doc-engmt-pre-form.module').then( m => m.DocEngmtPreFormPageModule)
  },
  {
    path: 'webpush',
    loadChildren: () => import('./webpush/webpush.module').then( m => m.WebpushPageModule)
  },
  {
    path: 'circlealldoctors/:id',
    resolve: {
      circleDoctors: DataResolverService
    },
    loadChildren: () => import('./circlealldoctors/circlealldoctors.module').then( m => m.CirclealldoctorsPageModule)
  },
  {
    path: 'answervideocall',
    loadChildren: () => import('./answervideocall/answervideocall.module').then( m => m.AnswervideocallPageModule)
  },
  {
    path: 'corporate-forgot-password',
    loadChildren: () => import('./corporate-forgot-password/corporate-forgot-password.module').then( m => m.CorporateForgotPasswordPageModule)
  },
  {
    path: 'scratchcard-subscription',
    loadChildren: () => import('./scratchcard-subscription/scratchcard-subscription.module').then( m => m.ScratchcardSubscriptionPageModule)
  },
  {
    path: 'scratchjoin',
    loadChildren: () => import('./scratchjoin/scratchjoin.module').then( m => m.ScratchjoinPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'patient-login',
    loadChildren: () => import('./patient-login/patient-login.module').then( m => m.PatientLoginPageModule)
  },
  {
    path: 'patient-signup',
    loadChildren: () => import('./patient-signup/patient-signup.module').then( m => m.PatientSignupPageModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  },
  {
    path: 'home-tab',
    loadChildren: () => import('./home-tab/home-tab.module').then( m => m.HomeTabPageModule),
    
  },
  {
    path: 'specialists',
    loadChildren: () => import('./specialists/specialists.module').then( m => m.SpecialistsPageModule)
  },
  {
    path: 'show-circle-doctors/:id',
    resolve: {
      docData: DataResolverService
    },
    loadChildren: () => import('./show-circle-doctors/show-circle-doctors.module').then( m => m.ShowCircleDoctorsPageModule)
  },
  {
    path: 'package-questions/:id',
    resolve: {
      qformData: DataResolverService
    },
    loadChildren: () => import('./package-questions/package-questions.module').then( m => m.PackageQuestionsPageModule)
  },
  { path: 'welcome', component: WelcomeComponent },
  { path: 'preview', component: MeetingPreviewComponent },
  { path: 'settings', component: InputOutputSettingsComponent },
  { path: 'meeting', component: MeetingPageComponent},  
  {
    path: 'renewal-question',
    loadChildren: () => import('./renewal-question/renewal-question.module').then( m => m.RenewalQuestionPageModule)
  },
  {
    path: 'singleconsult',
    loadChildren: () => import('./singleconsult/singleconsult.module').then( m => m.SingleconsultPageModule)
  },
  {
    path: 'singleconsultdoctor/:id',
    resolve: {
      singleConsultData: DataResolverService
    },
    loadChildren: () => import('./singleconsultdoctor/singleconsultdoctor.module').then( m => m.SingleconsultdoctorPageModule)
    
  },
  {
    path: 'package-ques-ans-view',
    loadChildren: () => import('./package-ques-ans-view/package-ques-ans-view.module').then( m => m.PackageQuesAnsViewPageModule)
  },  
  {
    path: 'hockey',
    loadChildren: () => import('./hockey/hockey.module').then( m => m.HockeyPageModule)
  },
  {
    path: 'hockeyhome',
    loadChildren: () => import('./hockeyhome/hockeyhome.module').then( m => m.HockeyhomePageModule)
  },
  {
    path: 'ticket',
    loadChildren: () => import('./ticket/ticket.module').then( m => m.TicketPageModule)
  },
  {
    path: 'team',
    loadChildren: () => import('./team/team.module').then( m => m.TeamPageModule)
  },
  {
    path: 'table',
    loadChildren: () => import('./table/table.module').then( m => m.TablePageModule)
  },
  {
    path: 'content',
    loadChildren: () => import('./content/content.module').then( m => m.ContentPageModule)
  },
  {
    path: 'spec-list',
    loadChildren: () => import('./spec-list/spec-list.module').then( m => m.SpecListPageModule)
  },
  {
    path: 'joincall',
    loadChildren: () => import('./joincall/joincall.module').then( m => m.JoincallPageModule)
  },
  {
    path: 'testvideocall',
    loadChildren: () => import('./testvideocall/testvideocall.module').then( m => m.TestvideocallPageModule)
  },
  {
    path: 'ultra-subscribe-family-member',
    loadChildren: () => import('./ultra-subscribe-family-member/ultra-subscribe-family-member.module').then( m => m.UltraSubscribeFamilyMemberPageModule)
  }
];

@NgModule({
  declarations: [
    MeetingPreviewComponent,
    RoundTranparentIconButtonComponent,
    InputOutputSettingsComponent,
    SoundMeterComponent,
    CameraPreviewComponent,
    WelcomeComponent,
    MeetingPageComponent,
    AgoraVideoPlayerDirective,
    MeetingControlsComponent,
    MeetingParticipantComponent,
    MeetingParticipantControlsComponent,
    SoundVisualizerComponent
  ],
  imports: [
    CommonModule,
    //RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    ReactiveFormsModule,
    HttpClientModule,
    FontAwesomeModule,
    NgxAgoraSdkNgModule.forRoot({
      AppID: '9574c9a0ff9d41aa842ef6cc89e2904f',
      Video: { codec: 'h264', mode: 'rtc', role: 'host' }
    }),
    RouterModule.forRoot(routes, { preloadingStrategy:PreloadAllModules,useHash: true })
    //RouterModule.forRoot(routes, {preloadingStrategy:SimpleLoadingStrategyService, useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
