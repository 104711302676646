// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyBrF9C3m2KSRI7b-ctKLInSaFcVRacZT5Y",
  authDomain: "ionic-push-demo-6914e.firebaseapp.com",
  databaseURL: "https://ionic-push-demo-6914e.firebaseio.com",
  projectId: "ionic-push-demo-6914e",
  storageBucket: "ionic-push-demo-6914e.appspot.com",
  messagingSenderId: "475705950967",
  appId: "1:475705950967:web:173de019774ed40d4309f2"
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
