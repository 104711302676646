import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, ViewChild, 
  ChangeDetectorRef,HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { IMediaTrack, IRemoteUser, NgxAgoraSdkNgService } from 'ngx-agora-sdk-ng';

import { MediaService } from '../../shared/services/media.service';
import { TokenService } from '../../shared/services/token.service';
import { DataService } from '../../services/data.service';
import { AuthService } from "../../auth.service";
import { LoadingController, ToastController, ModalController, Platform,NavController } from "@ionic/angular";
import { AnswervideocallPage } from '../../answervideocall/answervideocall.page';
import { WebsocketService } from '../../services/websocket.service';
import { SwasthStorageService } from '../../storage/swasth-storage.service';

import {
  NgxAgoraService,
  Stream,
  AgoraClient,
  ClientEvent,
  StreamEvent,
  AgoraRTC,
} from 'ngx-agora';

export interface IMeetingUser {
  type: 'local' | 'remote';
  user?: IRemoteUser;
  mediaTrack?: IMediaTrack;
}

@Component({
  selector: 'app-meeting-page',
  templateUrl: './meeting-page.component.html',
  styleUrls: ['./meeting-page.component.scss'],
})
export class MeetingPageComponent implements OnInit, OnDestroy {
  // @HostListener('window:popstate', ['$event'])
  // onPopState(event) {
  //   this.refuseCall();
  // }
  @ViewChild('localVideo', { static: true }) localVideo?: ElementRef;
  link = '';
  channel = '';
  subscriptions: Subscription[] = [];
  userList: IMeetingUser[] = [];
  audioInId = '';
  videoInId = '';
  audioOutId = '';
  token = '';
  mediaTrack?: IMediaTrack;
  pinnedUser?: IMeetingUser | null;
  circleCode: any;
  ptuserID: any;
  docuserID: any;
  calling = true;
  remoteVideo = false;
  showCallingVideo = true;
  localCallId = 'agora_local';
  localCallIdBeforeAccept = 'agora_doctor';
  agoraRTC: AgoraRTC;
  client: AgoraClient;
  localStream: Stream;
  uid: string;
  doctorProfile: any;
  doctorPicture: any;
  call: boolean = true;
  autoRefreshFirstTime = false;
  patientUid: any;
  videoObj:any;
  constructor(
    private activatedRoute: ActivatedRoute,
    private agoraService: NgxAgoraSdkNgService,
    private mediaService: MediaService,
    private tokenService: TokenService,
    private router: Router,
    private dataService: DataService,
    private cdRef: ChangeDetectorRef,
    private agoraService1: NgxAgoraService,
    public authService: AuthService,
    public modalCtrl: ModalController,
    public webSocket: WebsocketService,
    public platform: Platform,
    public swasthStorage: SwasthStorageService,
    public navCntrl: NavController
  ) {
    // this.platform.backButton.subscribeWithPriority(5, () => {
    //   this.refuseCall();
    // });
    window.onpopstate = (evt) => {
      //alert("back");
      //this.refuseCall();
      this.devicebackLeave();
    };
    this.uid = "12345";
  }

  ngOnInit(): void {
    this.startCall();
    this.webSocketCall();
  }

  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

  //Accept call invitation
  acceptCall() {
    this.calling = false;
    this.call = false;
    this.prepareVideoCall();
    // For Web App
    //this.answerVideoCall();
  }



  refuseCall() {
    this.showCallingVideo = false;
    this.call = false;
    this.calling = false;
    // this.agoraService.leave();
    // this.mediaTrack?.stop();
    if (this.localStream) {
      this.localStream.stop();
      this.localStream.close();
    }
    if (this.client) {
      this.client.stopLiveStreaming;
      this.client.stopChannelMediaRelay;
    }

    this.webSocket.sendVideoCallCutNotificationToDoctor(this.docuserID, this.ptuserID, this.circleCode, "patientrejectcall");
    setTimeout(() => {
      this.swasthStorage.setStorage('videocall','yes');
      // for web
      this.navCntrl.back();

      //for apk
      // this.router.navigateByUrl('/circle-list');
    }, 500);

  }

  devicebackLeave() {
    this.showCallingVideo = false;
    this.call = false;
    this.calling = false;
    // this.agoraService.leave();
    // this.mediaTrack?.stop();
    if (this.localStream) {
      this.localStream.stop();
      this.localStream.close();
    }
    if (this.client) {
      this.client.stopLiveStreaming;
      this.client.stopChannelMediaRelay;
    }

    this.webSocket.sendVideoCallCutNotificationToDoctor(this.docuserID, this.ptuserID, this.circleCode, "patientrejectcall");
    
  }

  async joinVideo(): Promise<void> {
    this.mediaTrack = await this.agoraService.join(this.channel, this.token)
      .WithCameraAndMicrophone(this.audioInId, this.videoInId)
      .Apply();
  }

  onLocalMic(value: boolean): void {
    !value ? this.mediaTrack ?.microphoneUnMute() : this.mediaTrack ?.microphoneMute();
  }

  onLocalCamera(value: boolean): void {
    !value ? this.mediaTrack ?.cameraOn() : this.mediaTrack ?.cameraOff();
  }

  onLocalPinned(value: boolean): void {
    const localuser = this.userList.find(user => user.type === 'local');
    if (localuser) {
      this.onPin(localuser);
    }
  }

  onLocalLeave(): void {
    this.agoraService.leave();
    this.mediaTrack ?.stop();
    this.swasthStorage.setStorage('videocall','yes');
    // for web
    this.navCntrl.back();

    //for apk
    // this.router.navigateByUrl("/circle-list");
  }

  onPin(user: IMeetingUser): void {
    if (user.type === 'local') {
      if (this.pinnedUser && this.pinnedUser ?.type === 'local') {
        this.pinnedUser = null;
        return;
      }
      this.pinnedUser = user;
      return;
    }
    if (this.pinnedUser ?.type === 'local') {
      this.pinnedUser = user;
      return;
    }
    if (this.pinnedUser ?.user ?.uid === user.user ?.uid) {
      this.pinnedUser = null;
    } else {
      this.pinnedUser = user;
    }
  }

  getUnpinnedUsers(): IMeetingUser[] {
    if (this.pinnedUser ?.type === 'local') {
      return this.userList.filter(user => user.type !== 'local');
    }
    return this.userList.filter(user => user.user ?.uid !== this.pinnedUser ?.user ?.uid);
  }
  ionViewWillEnter() {    
    forkJoin([
      this.activatedRoute.queryParams.pipe(take(1)),
      this.mediaService.selectedAudioInputId.pipe(take(1)),
      this.mediaService.selectedAudioOutputId.pipe(take(1)),
      this.mediaService.selectedVideoInputId.pipe(take(1)),
    ])
      .pipe(
        take(1),
      ).subscribe(([params,aInId, aOutId, vInId]) => {
        this.circleCode=params.circlecode;
        this.ptuserID=params.ptuserid;
        this.docuserID=params.docuserid;
        this.link = params.meetLink;
        this.channel = params.channel;
        this.getDoctorProfile(this.docuserID);
      });
    //this.prepareVideoCall();
  }
  ionViewDidLeave() {
    this.onLocalMic(false);
    this.onLocalCamera(false);
  }

  getDoctorProfile(docuid) {
    this.authService
      .getDoctorProfile(docuid)
      .subscribe(data => {
        this.doctorProfile = data;
        //console.log("Doctor Profile==>",this.doctorProfile);
        this.doctorPicture = this.authService.S3BASE_URL + this.doctorProfile.profilePicture;
      })
  }

  prepareVideoCall() {
    // this.patientUid = this.randomPatient();
    this.patientUid = 0;
    forkJoin([
      this.activatedRoute.queryParams.pipe(take(1)),
      this.mediaService.selectedAudioInputId.pipe(take(1)),
      this.mediaService.selectedAudioOutputId.pipe(take(1)),
      this.mediaService.selectedVideoInputId.pipe(take(1)),
    ])
      .pipe(
        take(1),
      ).subscribe(([params, aInId, aOutId, vInId]) => {
        this.circleCode = params.circlecode;
        this.ptuserID = params.ptuserid;
        this.docuserID = params.docuserid;
        this.link = params.meetLink;
        this.channel = params.channel;
        if (this.link) {
          const result = this.tokenService.getChannel(this.link);
          if (result.error) {
            alert(result.error);
            this.router.navigate(['/..']);
            return;
          }
          this.channel = result.channel as string;
        }
        this.tokenService.getToken(this.channel, this.patientUid);
        this.audioInId = aInId;
        this.videoInId = vInId;
        this.audioOutId = aOutId;
      });

    const tokenSub = this.tokenService.token.pipe(take(1)).subscribe(token => {
      this.token = token;
      this.joinVideo();
    });
    this.subscriptions.push(tokenSub);

    const remoteUserLeaveSubs = this.agoraService.onRemoteUserLeft().subscribe(leftuser => {
      this.userList = this.userList.filter(user => user.user ?.uid !== leftuser.user.uid);
      this.onLocalLeave();
      if (this.pinnedUser && this.pinnedUser.user ?.uid && this.pinnedUser.user.uid === leftuser.user.uid) {
        this.pinnedUser = null;
      }
    });
    this.subscriptions.push(remoteUserLeaveSubs);

    const remoteUserChangeSubs = this.agoraService.onRemoteUsersStatusChange().subscribe(status => {
      switch (status.connectionState) {
        case 'CONNECTED':
          this.remoteVideo = true;
          this.localStream.stop();
          this.localStream.close();
          if (!this.userList.find(user => user.user ?.uid === status.user.uid)) {
            status.user.audioTrack ?.setVolume(400);
            this.pinnedUser = { type: 'remote', user: status.user };
            // this.userList.push({ type: 'remote', user: status.user });
          }
          break;
        case 'DISCONNECTED':
        case 'DISCONNECTING':
        case 'RECONNECTING':
          const currentUserIndex = this.userList.findIndex(user => user.user ?.uid === status.user.uid);
          if (currentUserIndex >= 0) {
            this.userList[currentUserIndex] = { type: 'remote', user: status.user };
            if (this.pinnedUser && this.pinnedUser.user ?.uid && this.pinnedUser.user.uid === status.user.uid) {
              status.user.audioTrack ?.setVolume(400);
              this.pinnedUser = { type: 'remote', user: status.user };
            }
          }
          break;
      }
    });
    this.subscriptions.push(remoteUserChangeSubs);

    const localUserJoinedSubs = this.agoraService.onLocalUserJoined().subscribe(track => {
      this.userList.push({ type: 'local', mediaTrack: track.track });
    });
    this.subscriptions.push(localUserJoinedSubs);
  }

  startCall() {

    //this.assignClientHandlers();
    // Added in this step to initialize the local A/V stream
    this.localStream = this.agoraService1.createStream({
      streamID: this.uid,
      audio: true,
      video: true,
      screen: false,
    });
    this.assignLocalStreamHandlers();
    // Join and publish methods added in this step
    this.initLocalStream(() =>
      this.join(
        (uid) => this.publish(),
        (error) => console.error(error)
      )
    );
  }

  join(
    onSuccess?: (uid: number | string) => void,
    onFailure?: (error: Error) => void
  ): void {
    //this.client.join(this.rtcToken, this.channelName, this.uid, onSuccess, onFailure);
  }

  publish(): void {
    let error = false
    this.client.publish(this.localStream, (err) => {
      error == true;
      // console.log('Publish local stream error: ' + err)
    }
    );
    if (error == false) {

    }
  }


  private getRemoteId(stream: Stream): string {
    return `agora_remote-${stream.getId()}`;
  }
  private assignLocalStreamHandlers(): void {
    this.localStream.on(StreamEvent.MediaAccessAllowed, () => {
      // console.log('accessAllowed');
    });

    // The user has denied access to the camera and mic.
    this.localStream.on(StreamEvent.MediaAccessDenied, () => {
      // console.log('accessDenied');
    });
  }

  private initLocalStream(onSuccess?: () => any): void {
    this.localStream.init(
      () => {
        // The user has granted access to the camera and mic.
        this.localStream.play(this.localCallId);
        if (onSuccess) {
          onSuccess();
        }
      },
      (err) => console.error('getUserMedia failed', err)
    );
  }

  ionViewWillLeave() {
    if (this.localStream) {
      this.localStream.stop();
      this.localStream.close();
    }
    if (this.client) {
      this.client.stopLiveStreaming;
      this.client.stopChannelMediaRelay;
    }
    this.agoraService.leave();
    this.mediaTrack ?.stop();
    //this.webSocket.sendVideoCallCutNotificationToDoctor(this.docuserID,this.ptuserID,this.circleCode,"patientrejectcall");
    //this.refuseCall();
  }

  webSocketCall() {
    this.webSocket.doctorEndCall.subscribe((data) => {
      if (data && this.autoRefreshFirstTime == false) {
        this.autoRefreshFirstTime = true;
        // console.log("Doctor End Call Web Socket==>", data);
        this.showCallingVideo = false;
        this.call = false;
        this.calling = false;
        // this.agoraService.leave();
        // this.mediaTrack?.stop();
        if (this.localStream) {
          this.localStream.stop();
          this.localStream.close();
        }
        if (this.client) {
          this.client.stopLiveStreaming;
          this.client.stopChannelMediaRelay;
        }
        setTimeout(() => {
          this.swasthStorage.setStorage('videocall','yes');  
          // for web
          this.navCntrl.back();  

          // for apk   
        //  this.router.navigateByUrl('/circle-list');
        }, 1000);

      }
    });
  }
  randomPatient(): number {
    let randPatient = Math.floor(Math.random() * 20000000) + 1;
    return randPatient;
  }
}
