import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChatNotificationService {  
  doctorListMessage = {};  
  lastDoctorList = {};
  doctorListMessageLs = {};
  lastDoctorListLs = {};
  constructor() { }


  creatDoctorSubject(ccode) {
    this.doctorListMessage[ccode] = new Subject<any>();
    // console.log(this.doctorListMessage)
  }

  notificationChangesDoctors(ccode,doctorMessages){
    this.lastDoctorList = doctorMessages;        
    this.doctorListMessage[ccode].next(doctorMessages[ccode]);
  }

  getDoctorListMessages(ccode){
    // console.log(ccode);
    this.notificationChangesDoctors(ccode,this.lastDoctorList);
    return this.doctorListMessage[ccode].asObservable();
  }

  creatDoctorSubjectLs(ccode, docpr) {
    this.doctorListMessageLs[ccode] = {};
    docpr.forEach(docPro => {
      this.doctorListMessageLs[ccode][docPro.docProfile.docUserID] = new Subject<any>();
    });
    
  }

  notificationChangesDoctorsLs(ccode,doctorMessages,docid){
    //console.log("chat add value--->",doctorMessages);    
    this.lastDoctorListLs = doctorMessages;
    this.doctorListMessageLs[ccode][docid].next(doctorMessages);
  }

  getDoctorListMessagesLs(ccode, docid){
    this.notificationChangesDoctorsLs(ccode,this.lastDoctorListLs, docid);
    // console.log("chat doc ls msg-->", this.doctorListMessageLs[ccode][docid].asObservable());
    return this.doctorListMessageLs[ccode][docid].asObservable();
  }
}
