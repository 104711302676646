import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from "../auth.service";
import { DataService } from '../services/data.service';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import * as firebase from 'firebase';
import { SwasthStorageService } from "../storage/swasth-storage.service";
import { LoginAuthService } from "../services/login-auth.service";

@Component({
  selector: 'app-chatpage',
  templateUrl: './chatpage.page.html',
  styleUrls: ['./chatpage.page.scss'],
})
export class ChatpagePage implements OnInit {

  @Input() circlecode: string;
  @Input() ptuid: string;
  @Input() docuid:string;

  chatRoom: any;
  ptChatDetail: any;
  chats = [];
  ptDetail:any;
  accType:any;
  chatRestrictDetail:any;

  constructor(public authService: AuthService,
    public dataService: DataService,
    public router: Router,
    public popoverCtrl: PopoverController,
    public swStorage: SwasthStorageService,
    public loginAuth: LoginAuthService) { }

  ngOnInit() {
    alert(this.circlecode);
    alert(this.ptuid);
    alert(this.docuid);
    this.authService
      .getPatientChatByPatientUserid(
        this.ptuid,
        this.circlecode
      )
      .subscribe((data) => {
        if (data != null) {
          this.ptChatDetail = data;
          this.getFireBaseMsg(this.ptChatDetail.chatRoomKey);
        }
        });

        this.swStorage.getStorage(this.loginAuth.LOGINUSER_PROFILE).then((ptdata) => {
          if (ptdata != null) {
            this.ptDetail = ptdata;
            alert(this.ptDetail.userID)
          }
        });        

        if (this.ptDetail.accountType == null && this.ptDetail.createdByUserID != this.ptDetail.userID) {
          this.accType = 'Family';
        } else {
          this.accType = this.ptDetail.accountType;
        }
        if(this.ptuid!=undefined && this.circlecode!=undefined && this.accType!=undefined)
        {
        let chatObj: any = {
          userID: this.ptuid,
          circleCode: this.circlecode
        }
        // console.log("chatRestrci data-->", chatObj);
        this.authService
          .getChatRestrictDetail(chatObj)
          .subscribe(data => {
            //alert("chatRestrcit data");
            //console.log("chatRestrci data-->", data);
            if (data != null) {
              this.chatRestrictDetail = data;
            }
          });   
        } 
  }

  openChat() {
    let ctRoom: any = {
      docuid: this.docuid,
      circleCode: this.circlecode,
      ptuid: this.ptuid,
      docStatus: "Active",
      loginUserDetail: this.ptDetail,
      ptDetail: this.ptDetail,
      chatRoomKey: this.ptChatDetail.chatRoomKey,
      chatRestrict: this.chatRestrictDetail,
      chatRoomStatus: this.ptChatDetail.status
      // circleDetail: this.circleDetail
    };
    // console.log("ccode",this.circleDetail.vhEntity.circleCode);
    this.authService
      .getPtSingleCircle(this.circlecode, this.ptuid)
      .subscribe(data => {
        ctRoom.circleDetail = data;
        // this.circleDetail=data;
        this.dataService.setData(29, ctRoom);
        localStorage.setItem('ctRoom' + this.circlecode, JSON.stringify(ctRoom));
          this.router.navigate(["chatroom/29", { circleCode: this.circlecode }]);
      })
  }

  getFireBaseMsg(roomKey) {
    //alert(this.roomkey);
    firebase.database().ref('chatrooms/' + roomKey + '/chats').once('value', resp => {
      this.chats = snapshotToArray(resp);
      //console.log("chat", this.chats);      
    });
  }

  cancel() {
    this.popoverCtrl.dismiss();
  }

}

export const snapshotToArray = snapshot => {
  let returnArr = [];
  snapshot.forEach(childSnapshot => {
    let item = childSnapshot.val();
    item.key = childSnapshot.key;
    returnArr.push(item);
  });
  return returnArr;
};
